import { useState } from "react";
import { client } from "../sanity";
import {
  publicationQuery,
  publicationSearchQuery,
  recentPublicationQuery,
  publicationItemQuery,
  galleryItemQuery,
  galleryQuery,
} from "../query/sanity";

function useFetchSanityData() {
  const [error, setError] = useState("");

  const [publications, setPublications] = useState([]);
  const [recentPublications, setRecentPublications] = useState([]);
  const [publicationItem, setPublicationItem] = useState(null);
  const [searchPublicationText, setSearchPublicationText] = useState("");
  const [loadingPublications, setLoadingPublications] = useState(false);
  const [loadingRecentPublications, setLoadingRecentPublications] =
    useState(false);

  const [gallery, setGallery] = useState([]);
  const [galleryItem, setGalleryItem] = useState(null);
  const [loadingGallery, setLoadingGallery] = useState(false);

  const handleError = (error) => {
    // console.log(error);
    setError(error);
  };

  // publications
  const handlePublicationData = (data) => {
    // console.log(data);
    setPublications(data);
    setLoadingPublications(false);
  };
  const fetchPublications = () => {
    setLoadingPublications(true);
    setError("");
    if (searchPublicationText.trim() !== "") {
      client
        .fetch(publicationSearchQuery(searchPublicationText))
        .then((data) => handlePublicationData(data))
        .catch((error) => handleError(error));
    } else {
      client
        .fetch(publicationQuery)
        .then((data) => handlePublicationData(data))
        .catch((error) => handleError(error));
    }
  };
  const fetchRecentPublication = () => {
    setLoadingRecentPublications(true);
    setError("");
    client
      .fetch(recentPublicationQuery)
      .then((data) => {
        // console.log(data);
        setLoadingRecentPublications(false);
        setRecentPublications(data);
      })
      .catch((error) => {
        setLoadingRecentPublications(false);
        handleError(error);
      });
  };
  const handlePublicationSearch = (event) => {
    setSearchPublicationText(event.target.value);
  };
  const fetchPublicationItem = (slug) => {
    setError("");
    client
      .fetch(publicationItemQuery, { slug: slug })
      .then((data) => {
        // console.log(data);
        setPublicationItem(data);
      })
      .catch((error) => handleError(error));
  };

  // gallery
  const fetchGallery = () => {
    setLoadingGallery(true);
    setError("");

    client
      .fetch(galleryQuery)
      .then((data) => {
        // console.log(data);
        setGallery(data);
        setLoadingGallery(false);
      })
      .catch((error) => handleError(error));
  };
  const fetchGalleryItem = (slug) => {
    setError("");
    client
      .fetch(galleryItemQuery, { slug: slug })
      .then((data) => {
        // console.log(data);
        setGalleryItem(data);
      })
      .catch((error) => handleError(error));
  };

  return {
    error,
    publications,
    recentPublications,
    publicationItem,
    searchPublicationText,
    loadingPublications,
    loadingRecentPublications,
    fetchPublications,
    fetchRecentPublication,
    fetchPublicationItem,
    handlePublicationSearch,
    gallery,
    galleryItem,
    loadingGallery,
    fetchGallery,
    fetchGalleryItem,
  };
}

export default useFetchSanityData;

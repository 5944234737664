import React from "react";
import { Link } from "react-router-dom";

export default function Card({ item }) {
  const { title, coverImage, slug } = item;
  return (
    <Link to={`/gallery/${slug.current}`} className="">
      <div className="min-w-full overflow-hidden">
        <img
          src={coverImage?.image}
          className="transition-all hover:scale-110 h-[321px] w-full sm:w-[352px] object-cover"
          alt=""
        />

        <div className="p-4 bg-grey-10">
          <h2 className="uppercase text-black text-lg font-medium !leading-[32px] h-[64px] line-clamp-2 text-ellipsis">
            {title ?? ""}
          </h2>
        </div>
      </div>
    </Link>
  );
}

import React from "react";

export default function Company() {
  return (
    <section className="bg-grey-900 py-[54px]">
      <div className="container">
        <h3 className="py-3 text-primary text-center text-[28px] font-bold tracking-[-0.56px] border-y-2 border-solid border-primary-800 max-w-[1008px] mx-auto">
          “One-Stop-Shop for Nationwide Gas Commercialization”
        </h3>
      </div>
    </section>
  );
}

import React from "react";
import GeneralAccordion from "../../../common/accordion/generalAccordion";

const accordionData = [
  {
    question: "Identify your concern",
    answer:
      "Determine the nature of the issue (e.g., fraud, harassment, regulatory breaches).",
  },
  {
    question: "Submit your Report",
    answer:
      "Use the secure whistleblowing system to share your concerns anonymously or with your contact details for follow-up.",
  },
  {
    question: "Investigation",
    answer: "All reports are reviewed thoroughly and handled confidentially.",
  },
  {
    question: "Resolution and Feedback",
    answer:
      "Actions are taken where necessary, and feedback is provided when possible.",
  },
];

export default function WhistleblowerPolicyNavigation() {
  return (
    <section className="py-20 bg-primary-50">
      <div className="container">
        <h3 className="uppercase z-10 py-3 text-black text-xl md:text-[28px] font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span>How it Works: A Simple and
          Secure Process
        </h3>

        <GeneralAccordion accordionData={accordionData} type="none" />
      </div>
    </section>
  );
}

import React from "react";

import Layout from "../components/layout";
import Team from "../components/management/team";
import InnerBanner from "../common/innerBanner";

const ManagementPage = () => {
  return (
    <Layout contact={true}>
      <InnerBanner
        name="small"
        title="OUR MANAGEMENT TEAM"
        description=" a highly skilled Management team Driving Innovation and Excellence in Nigeria's Gas Sector"
        bannerImage="bg-management-bannerMobile md:bg-management-bannerDesktop"
      />
      <Team />
    </Layout>
  );
};

export default ManagementPage;

import { Link } from "react-router-dom";
import logo from "../../assets/images/logo/logo3.svg";
import Navbar from "./navbar";
import Button from "../../common/button";

const Header = ({ toggleContactModal }) => {
  return (
    <header
      id="header"
      className="header w-full !fixed lg:!relative z-[999] md:z-10 top-0 left-0 bg-white lg:bg-transparent"
    >
      <div className="container">
        <div className="flex items-center justify-between gap-16 lg:gap-12 py-[18px] lg:py-3 lg:py-6 px-0">
          <Link to="/" aria-label="homepage">
            <img
              src={logo}
              className="block lg:hidden"
              width={120}
              alt="logo"
            />
            <img
              src={logo}
              className="hidden lg:block min-w-[120px]"
              alt="logo"
            />
          </Link>

          <Navbar toggleContactModal={toggleContactModal} />
          <div
            className="hidden lg:block"
            onClick={() => {
              toggleContactModal(true);
            }}
          >
            <Button>Get started</Button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import Icons from "../../../assets/icons";
import logo from "../../../assets/images/logo/logo3.svg";
import { Link } from "react-router-dom";
import useBiographyModal from "../../../hooks/useBiographyModal";

export default function BiographyModal() {
  const { toggleBiographyModal, isBiographyModalOpen, biography } =
    useBiographyModal();

  const { image, details, name, role, link } = biography ?? {};

  const paragraphs = details?.split("/n");

  return (
    <section
      className={`bg-grey-50 md:bg-white flex h-full w-full fixed top-[76px] md:top-0 left-0 z-30 transition-all ease-in-out duration-300 h-auto overflow-auto ${
        isBiographyModalOpen
          ? "visible min-h-screen translate-y-0 opacity-100"
          : "invisible min-h-0 translate-y-[-100%] opacity-0"
      }`}
    >
      <div className="container">
        <div className="flex items-center justify-between pt-6">
          <Link to="/" aria-label="homepage">
            <img src={logo} width={150} alt="logo" />
          </Link>

          <div
            onClick={() => {
              toggleBiographyModal(false);
            }}
            className="cursor-pointer bg-primary border boder-solid border-primary-100 text-white h-8 md:h-12 w-8 md:w-12 rounded-full flex items-center justify-center"
          >
            {Icons.ic_close}
          </div>
        </div>

        <div className="flex flex-col md:flex-row gap-10 md:gap-[136px] py-20   ">
          <div className="md:space-y-8 max-w-[384px] md:min-w-[384px] border md:border-none border-grey-300">
            <img
              src={image}
              alt={name}
              className="md:border md:border-grey-300 w-full max-w-[384px] md:min-w-[384px]"
            />
            <div className="space-y-3 md:space-y-2 px-4 md:px-0 py-3 md:py-0">
              <div className="flex justify-between items-center">
                <h6 className="uppercase text-black text-xl font-medium !leading-[32px] tracking-[-2%] ">
                  {name}
                </h6>
                {link && (
                  <a href={link} target="_blank" rel="noreferrer">
                    <img src={Icons.ic_linkedin_green} alt="" />
                  </a>
                )}
              </div>
              <p className="text-grey-600 text-sm !leading-[20.3px]">{role}</p>
            </div>
          </div>

          <div className="space-y-2 w-full min-h-fit">
            <h6 className="text-xl tracking-[-2%] !leading-[32px]">
              BIOGRAPHY
            </h6>
            <p className="tracking-[-2%] !leading-[32px] text-grey-600 space-y-3">
              {paragraphs?.map((paragraph, index) => (
                <span key={index} className="block">
                  {paragraph}
                </span>
              ))}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

import { useState } from "react";
import { validate } from "react-email-validator";
import { toast } from "react-toastify";

const useNewsLetter = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [loading, setLoading] = useState(false);

  const submitForm = async () => {
    setLoading(true);
    const options = {
      method: "POST",
      redirect: "follow",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const response = await fetch(
        `https://gacn-nest-server-2f0f7095d25c.herokuapp.com/api/v1/newsletter/create/${email}`,
        options
      );

      // console.log(response);
      const data = await response.json();
      // console.log(data, data.message);
      if (response.status === 201) setIsSuccessful(true);
      else toast.error(data?.message ?? "Something went wrong");
    } catch (error) {
      // console.log(error);
      toast.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (value) => {
    setEmail(value);
    if (!value || !validate(value))
      setEmailError("Please enter a valid email address");
    else setEmailError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(email, emailError);

    if (!emailError || validate(email)) submitForm();
    else setEmailError("Please enter a valid email address");
  };

  return {
    loading,
    email,
    emailError,
    isSuccessful,
    handleChange,
    handleSubmit,
  };
};

export default useNewsLetter;

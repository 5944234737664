import React from "react";

import GalleryDetails from "../components/gallery/details";
import Layout from "../components/layout";

const GalleryDetailPage = () => {
  return (
    <Layout>
      <GalleryDetails />
    </Layout>
  );
};

export default GalleryDetailPage;

import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import Code from "../components/codeOfConduct/code";

const CodeOfConductPage = () => {
  return (
    <Layout>
      <InnerBanner
        title="OUR CODE OF CONDUCT"
        description="Guiding Principles for Integrity and Professionalism"
      />
      <Code />
    </Layout>
  );
};

export default CodeOfConductPage;

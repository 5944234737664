import React from "react";
import GeneralAccordion from "../../../common/accordion/generalAccordion";

const accordionData = [
  {
    question: "How is anonymity protected?",
    answer:
      "Reports can be submitted without revealing your identity. The system does not track IP addresses or other identifying information unless you choose to provide contact details for follow-up.",
  },
  {
    question: "What protections do whistleblowers have?",
    answer:
      "GACN provides protections under its whistleblower policies, including protection from retaliation such as job loss, demotion, harassment, or discrimination. In some jurisdictions, legal protections also apply.",
  },
  {
    question: "Is this system secure?",
    answer:
      "Yes, the system is designed with strong security measures to protect the data. Access is restricted, and encryption is typically used to secure all submitted information.",
  },
];

export default function WhistleblowerPolicyAccordion() {
  return (
    <section className="py-20">
      <div className="container">
        <h3 className="uppercase z-10 mb-10 md:mb-16 py-3 max-w-[656px] mx-auto text-black text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span> Your Questions, Answered
        </h3>
        <GeneralAccordion accordionData={accordionData} />
      </div>
    </section>
  );
}

import React from "react";
import { Link } from "react-router-dom";
import Icons from "../../../assets/icons";

export default function Card({ item }) {
  const { title, smallDescription, slug, publicationImage } = item;

  return (
    <div className="max-w-[383px] w-[312px] md:w-[383px] lg:w-[344px] min-w-[312px] md:min-w-[383px] lg:min-w-[344px] ">
      <img
        src={publicationImage?.image}
        className="object-cover h-[225px] max-h-[225px] w-[312px] md:w-[383px] lg:w-[344px] min-w-[312px] md:min-w-[383px] lg:min-w-[344px]"
        alt=""
      />

      <div className="p-6 bg-grey-50">
        <h2 className="text-black text-lg font-bold !leading-[32px] mb-4 h-[64px] line-clamp-2 text-ellipsis">
          {title}
        </h2>
        <p className="text-grey-600 text-base !leading-[32px] mb-6 h-[64px] line-clamp-2 text-ellipsis">
          {smallDescription}
        </p>
        <Link
          to={`/publications/${slug?.current}`}
          className="block bg-white p-2 border-2 border-solid border-grey-10 rounded-lg flex justify-center items-center gap-2 w-full"
        >
          <span className="text-primary text-sm uppercase font-semibold tracking-[1.68px]">
            Continue reading
          </span>
          {Icons.ic_new_tab}
        </Link>
      </div>
    </div>
  );
}

import React from "react";

import layerBlurYellow from "../../../assets/images/value/layer_blur_yellow.svg";
import company1 from "../../../assets/images/about/company1.png";
import company2 from "../../../assets/images/about/company2.png";

export default function HowWeStarted() {
  return (
    <section className="bg-white py-20 relative">
      <img src={layerBlurYellow} className="absolute top-0 left-0" alt="" />
      <div className="container">
        <h3 className="z-10 mb-10 md:mb-16 py-3 text-black text-xl md:text-[28px] font-bold tracking-[-0.56px] border-y border-solid border-grey-500 ">
          <span className="text-primary-200">/</span> HOW WE STARTED
        </h3>
        <div className="space-y-16 md:space-y-[132px] ">
          <div className="flex flex-col-reverse md:flex-row gap-16 xl:gap-[136px]">
            <img
              src={company1}
              alt=""
              className="w-full md:w-1/2 md:min-w-[46%] xl:min-w-[44%]"
            />
            <div className="space-y-6 w-full md:w-1/2">
              <div className="tracking-[-2%]">
                <span className="text-grey-400 font-semibold">
                  INCORPORATED IN
                </span>
                <h2 className="text-black text-[28px] !leading-[33.6px] font-semibold">
                  2010
                </h2>
              </div>
              <p className="text-grey-600 text-base md:text-lg !leading-[32px]">
                Gas Aggregator Company Nigeria Ltd/Gte (“GACN”) was incorporated
                in January, 2010 in relations to the National Domestic Gas
                Supply and Pricing Regulations, 2008 as the “Strategic
                Aggregator” for the Nigerian domestic gas market and the vehicle
                for the implementation of the Nigerian Gas Master Plan and
                Management of Domestic Supply of Gas to the market.
              </p>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-16 lg:gap-[136px]">
            <div className="space-y-6 w-full md:w-1/2">
              <div className="tracking-[-2%]">
                <span className="text-grey-400 font-semibold">
                  SOLIDIFIED WITH THE
                </span>
                <h2 className="text-black text-[28px] !leading-[33.6px] font-semibold">
                  PETROLEUM INDUSTRY ACT, 2021
                </h2>
              </div>
              <p className="text-grey-600 text-lg !leading-[32px]">
                The strategic position of GACN is further solidified by the
                enactment of the Petroleum Industry Act, 2021 (the “PIA”) which
                amongst others, mandates the domestic gas aggregator to support
                and manage the implementation of the Domestic Gas Delivery
                Obligation for the purpose of enhancing natural gas utilisation
                in Nigeria.
              </p>
            </div>
            <img
              src={company2}
              alt=""
              className="w-full md:w-1/2 md:min-w-[46%] xl:min-w-[44%]"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

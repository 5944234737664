import Icons from "../../assets/icons";
import mobileImage1 from "../../assets/images/services/image2.png";
import mobileImage2 from "../../assets/images/services/image3.png";
import mobileImage3 from "../../assets/images/services/image4.png";
import mobileImage4 from "../../assets/images/services/image5.png";
import mobileImage5 from "../../assets/images/services/image6.png";
import mobileImage6 from "../../assets/images/services/image7.png";
import mobileImage7 from "../../assets/images/services/image8.png";
import mobileImage8 from "../../assets/images/services/image1.png";

import desktopImage1 from "../../assets/images/services/accordion1.png";
import desktopImage2 from "../../assets/images/services/accordion2.png";
import desktopImage3 from "../../assets/images/services/accordion3.png";
import desktopImage4 from "../../assets/images/services/accordion4.png";
import desktopImage5 from "../../assets/images/services/accordion5.png";
import desktopImage6 from "../../assets/images/services/accordion6.png";
import desktopImage7 from "../../assets/images/services/accordion7.png";
import desktopImage8 from "../../assets/images/services/accordion8.png";

import useAccordion from "../../hooks/useAccordion";

const accordionData = [
  {
    question: "Network Code Support",
    answer: {
      image: [desktopImage8, mobileImage8],
      text: "GACN liaises with the gas transportation network operator to ensure equitable and transparent access, supporting the smooth functioning of the gas market.",
    },
  },
  {
    question: "Price Aggregation and Escrow Management",
    answer: {
      image: [desktopImage1, mobileImage1],
      text: "We manage and oversee escrow accounts, ensuring transparent and efficient financial transactions in gas trading, alongside systematic price aggregation for fairness and market stability",
    },
  },
  {
    question: "Flexible Gas Supply Framework",
    answer: {
      image: [desktopImage2, mobileImage2],
      text: "Our flexible gas supply framework caters to diverse customer needs, offering adaptable supply arrangements for various market sectors, ensuring reliability and customization in gas delivery.",
    },
  },
  {
    question: "Gas Purchase Order Allocation",
    answer: {
      image: [desktopImage3, mobileImage3],
      text: "GACN efficiently allocates Gas Purchase Orders, balancing market demands with supply, and facilitating smooth operations in gas sales and aggregation agreements.",
    },
  },
  {
    question: "Gas Sector Support and Advisory Services",
    answer: {
      image: [desktopImage4, mobileImage4],
      text: "We provide comprehensive support and advisory services, offering expert insights and guidance on gas market dynamics, regulatory compliance, and strategic planning.",
    },
  },
  {
    question: "Gas Sourcing and Commercialization Facilitation",
    answer: {
      image: [desktopImage5, mobileImage5],
      text: "Our role in gas sourcing and commercialization involves facilitating the connection between gas producers and consumers, ensuring optimized supply chains and market growth",
    },
  },
  {
    question: "Gas Flare Commercialization Program Support",
    answer: {
      image: [desktopImage6, mobileImage6],
      text: "GACN is actively involved in supporting the Gas Flare Commercialization Program, aiming to minimize environmental impact while enhancing gas utilization for economic development.",
    },
  },
  {
    question: "Market Data Repository",
    answer: {
      image: [desktopImage7, mobileImage7],
      text: "We maintain a comprehensive market data repository, providing valuable insights and information on gas market trends, demand-supply dynamics, and pricing structures.",
    },
  },
];

export default function ServicesAccordion() {
  const { openIndex, toggleAccordion } = useAccordion(true);

  return (
    <div className="max-w-[1008px] mx-auto">
      {accordionData.map((item, index) => (
        <div
          className="border-b border-grey-300 py-8 flex items-start gap-8"
          key={index}
        >
          <div className="w-full">
            <h2 className="w-full text-lg md:text-xl text-left font-semibold flex justify-between">
              {item.question}
              <button
                className="min-h-[24px] min-w-[24px]"
                onClick={() => toggleAccordion(index)}
                aria-expanded={openIndex === index}
                aria-controls={`accordion-text-${index}`}
              >
                <img
                  src={Icons.ic_accordion}
                  alt=""
                  className={`w-[19px] transform origin-center transition duration-200 ease-out rotate-180 ${
                    openIndex === index && "!rotate-0"
                  }`}
                />
              </button>
            </h2>
            <div
              id={`accordion-text-${index}`}
              role="region"
              aria-labelledby={`accordion-title-${index}`}
              className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                openIndex === index
                  ? "grid-rows-[1fr] opacity-100 pt-6"
                  : "grid-rows-[0fr] opacity-0"
              }`}
            >
              <div className="overflow-hidden space-y-6">
                <img
                  src={item.answer.image[0]}
                  alt=""
                  className="w-full hidden sm:block"
                />
                <img
                  src={item.answer.image[1]}
                  alt=""
                  className="w-full h-[232px] object-cover block sm:hidden"
                />
                <p className="md:mx-auto md:max-w-[800px] text-grey-600 text-base md:text-lg !leading-[32px]">
                  {item.answer.text}
                </p>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

import React from "react";
import GeneralAccordion from "../../../common/accordion/generalAccordion";

const accordionData = [
  {
    question: "Expedited Conclusion of GSAA Negotiation and Operationalization",
    answer:
      "GACN has revolutionized the GSAA negotiation process, cutting down the timeline from over two years to just 3-6 months. This significant improvement accelerates the operationalization of gas agreements, fostering quicker market entry and responsiveness.",
  },
  {
    question: "Market Penetration and Gas Value Expansion Initiative",
    answer:
      "Our initiative here focuses on expanding the gas market's reach, notably through events like the South East Gas Utilization Forum. These forums facilitate dialogue on gas commercialization and create avenues for transportation solutions, vital for regional market expansion.",
  },
  {
    question: "Market Intelligence and Capacity Identification",
    answer:
      "Engaging in nationwide market research, GACN identifies specific energy needs across industries. This intelligence informs our strategies in providing bespoke gas-to-power solutions, enhancing both capacity and operational efficiency.",
  },
  {
    question: "Corporate Visibility Strategy",
    answer:
      "In a bid to strengthen our market presence and influence, GACN is implementing a robust corporate visibility strategy. This involves engaging stakeholders through various platforms and media, ensuring our pivotal role in the gas sector is widely recognized.",
  },
  {
    question: "Nigerian Gas Transportation Network Code Support",
    answer:
      "Collaborating with key entities like NMDPRA & NGIC, GACN plays an instrumental role in the effective implementation of the Nigerian Gas Transportation Network Code, ensuring compliance and efficiency in gas transportation.",
  },
  {
    question: "Gas Flare Commercialization Program Support",
    answer:
      "Actively participating in the Gas Flare Commercialization Program, GACN works towards environmental sustainability by reducing gas flaring through effective administrative roles and industry collaborations.",
  },
  {
    question: "Collaboration with Embedded Power Entities",
    answer:
      "Our collaboration extends to embedded power entities, focusing on pilot projects that explore innovative gas-to-power schemes, setting the stage for more sustainable and efficient energy solutions.",
  },
  {
    question: "Early Commencement of Gas Aggregation and Trading",
    answer:
      "Our collaboration extends to embedded power entities, focusing on pilot projects that explore innovative gas-to-power schemes, setting the stage for more sustainable and efficient energy solutions.",
  },
];

export default function InitiativesAccordion() {
  return (
    <section className="container !py-10 md:!py-20">
      <GeneralAccordion accordionData={accordionData} />
    </section>
  );
}

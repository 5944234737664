import React from "react";
import Icons from "../../assets/icons";
import { Link } from "react-router-dom";

const navigationData = [
  {
    title: "Innovating for the Future",
    description:
      "Explore GACN's Latest Initiatives in Gas Supply Optimization and Market expansion.",
    link: "/initiatives",
    linkDesciption: "Discover Our Initiatives",
  },
  {
    title: "Strategic Partnerships",
    description:
      "Building Collaborative Relationships for Enhanced Gas Sector Growth",
    link: "/partnerships",
    linkDesciption: "View Our Partnerships",
  },
];

export default function Navigation() {
  return (
    <div className="max-w-[912px] mx-auto">
      <div className="flex flex-col md:flex-row gap-10">
        {navigationData.map((item) => (
          <div
            key={item.title}
            className="flex flex-col gap-6 bg-grey-50 w-full sm:w-[435px] p-6 border border-grey-200 !leading-[32px] tracking-[-2%]"
          >
            <h5 className="text-black text-xl font-semibold">{item.title}</h5>
            <p className="text-grey-600">{item.description}</p>
            <Link
              to={item.link}
              className="block bg-white p-2 border-2 border-solid border-grey-10 rounded-lg flex items-center gap-2 mx-auto"
            >
              <span className="text-primary text-[0.7rem] sm:text-sm uppercase font-semibold tracking-[0.8px] sm:tracking-[1.68px]">
                {item.linkDesciption}
              </span>
              {Icons.ic_new_tab}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

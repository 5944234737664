import { createContext, useReducer } from "react";
import { client } from "../sanity";
import {
  newsFilteredQuery,
  newsItemQuery,
  newsQuery,
  newsSearchQuery,
  newsTagQuery,
  recentNewsQuery,
} from "../query/sanity";

const initialState = {
  news: [],
  recentNews: [],
  newsItem: null,
  searchNewsText: "",
  newsTag: "",

  error: "",
  loadingNews: false,
  loadingRecentNews: false,
};

const HANDLERS = {
  INITIALIZE_ITEM: "INITIALIZE_ITEM",
  INITIALIZE_NEWS: "INITIALIZE_NEWS",
  INITIALIZE_RECENT_NEWS: "INITIALIZE_RECENT_NEWS",

  SET_NEWS: "SET_NEWS",
  SET_RECENT_NEWS: "SET_RECENT_NEWS",
  SET_NEWS_ITEM: "SET_NEWS_ITEM",
  SEARCH_NEWS_TEXT: "SEARCH_NEWS_TEXT",
  NEWS_TAG: "NEWS_TAG",

  ERROR: "ERROR",
  RECENT_NEWS_ERROR: "RECENT_NEWS_ERROR",
  LOADING_NEWS: "LOADING",
  LOADING_RECENT_NEWS: "LOADING_RECENT_NEWS",
};

const NewsModal = createContext({
  newsTag: false,
  dispatch: () => null,
});

const reducer = (state, action) => {
  const {
    news,
    recentNews,
    newsItem,
    searchNewsText,
    newsTag,
    error,
    loadingNews,
    loadingRecentNews,
  } = action.payload;

  switch (action.type) {
    case HANDLERS.INITIALIZE_ITEM:
      return { ...state, error: "" };
    case HANDLERS.INITIALIZE_NEWS:
      return { ...state, loadingNews: true, error: "" };
    case HANDLERS.INITIALIZE_RECENT_NEWS:
      return { ...state, loadingRecentNews: true, error: "" };

    case HANDLERS.SET_NEWS:
      return {
        ...state,
        news,
        loadingNews: false,
      };
    case HANDLERS.SET_RECENT_NEWS:
      return {
        ...state,
        recentNews,
        loadingRecentNews: false,
      };
    case HANDLERS.SET_NEWS_ITEM:
      return {
        ...state,
        newsItem,
      };
    case HANDLERS.SEARCH_NEWS_TEXT:
      return {
        ...state,
        searchNewsText,
      };
    case HANDLERS.ERROR:
      return {
        ...state,
        error,
        loadingNews: false,
      };
    case HANDLERS.RECENT_NEWS_ERROR:
      return {
        ...state,
        error,
        loadingRecentNews: false,
      };
    case HANDLERS.LOADING_NEWS:
      return {
        ...state,
        loadingNews,
      };
    case HANDLERS.LOADING_RECENT_NEWS:
      return {
        ...state,
        loadingRecentNews,
      };
    case HANDLERS.NEWS_TAG:
      return {
        ...state,
        newsTag,
      };

    default:
      throw new Error("Something went wrong");
  }
};

const NewsModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const handleNewsData = (data) => {
    // console.log(data);
    dispatch({
      type: "SET_NEWS",
      payload: {
        ...state,
        news: data,
      },
    });
  };

  const handleError = (error) => {
    dispatch({
      type: "ERROR",
      payload: {
        ...state,
        error,
      },
    });
  };

  const fetchNews = (activeTab) => {
    // console.log(activeTab);
    dispatch({
      type: "INITIALIZE_NEWS",
      payload: {},
    });

    if (state.searchNewsText.trim() !== "") {
      client
        .fetch(newsSearchQuery(state.searchNewsText))
        .then((data) => handleNewsData(data))
        .catch((error) => handleError(error));
    }
    if (state.newsTag) {
      client
        .fetch(newsTagQuery, {
          tag: state.newsTag,
        })
        .then((data) => {
          // console.log(data);
          handleNewsData(data);
        })
        .catch((error) => handleError(error));
    } else {
      client
        .fetch(activeTab !== "All" ? newsFilteredQuery : newsQuery, {
          category: activeTab,
        })
        .then((data) => handleNewsData(data))
        .catch((error) => handleError(error));
    }
  };

  const handleNewsTag = (tag) => {
    // console.log(tag);
    dispatch({
      type: "NEWS_TAG",
      payload: { newsTag: tag },
    });
  };

  const fetchRecentNews = () => {
    dispatch({
      type: "INITIALIZE_RECENT_NEWS",
      payload: {},
    });
    client
      .fetch(recentNewsQuery)
      .then((data) => {
        // console.log(data);
        dispatch({
          type: "SET_RECENT_NEWS",
          payload: { recentNews: data },
        });
      })
      .catch((error) => {
        dispatch({
          type: "RECENT_NEWS_ERROR",
          payload: { error },
        });
      });
  };
  const handleNewsSearch = (event) => {
    dispatch({
      type: "SEARCH_NEWS_TEXT",
      payload: { searchNewsText: event.target.value },
    });
  };

  const fetchNewsItem = (slug) => {
    dispatch({
      type: "INITIALIZE_ITEM",
      payload: {},
    });
    client
      .fetch(newsItemQuery, { slug: slug })
      .then((data) => {
        // console.log(data);
        dispatch({
          type: "SET_NEWS_ITEM",
          payload: { newsItem: data },
        });
      })
      .catch((error) => handleError(error));
  };

  return (
    <NewsModal.Provider
      value={{
        news: state.news,
        recentNews: state.recentNews,
        newsItem: state.newsItem,
        searchNewsText: state.searchNewsText,
        newsTag: state.newsTag,
        error: state.error,
        loadingNews: state.loadingNews,
        loadingRecentNews: state.loadingRecentNews,
        state,
        dispatch,
        fetchNews,
        handleNewsTag,
        fetchRecentNews,
        handleNewsSearch,
        fetchNewsItem,
      }}
    >
      {children}
    </NewsModal.Provider>
  );
};

export { NewsModal, NewsModalProvider };

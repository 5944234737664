import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import Terms from "../components/termsAndCondition/terms";

const TermsAndConditionPage = () => {
  return (
    <Layout>
      <InnerBanner
        title="TERMS & CONDITIONS"
        description="Last updated: February, 2024"
      />
      <Terms />
    </Layout>
  );
};

export default TermsAndConditionPage;

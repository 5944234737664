/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { format } from "date-fns";

import useFetchSanityData from "../../../hooks/useFetchSanityData";
import { PortableText } from "@portabletext/react";
import Icons from "../../../assets/icons";
import Card from "./recentCard";
import { myPortableTextComponents } from "../../../utils/portableText";


export default function PublicationsDetail() {
  const {
    recentPublications,
    publicationItem,
    loadingRecentPublications,
    fetchRecentPublication,
    fetchPublicationItem,
  } = useFetchSanityData();

  const { slug } = useParams();

  useEffect(() => {
    fetchPublicationItem(slug);
    fetchRecentPublication();
  }, [slug]);

  if (!publicationItem) {
    return (
      <div className="py-[128px] flex justify-center items-center">
        {Icons.ic_loading}
      </div>
    );
  }
  return (
    <section className="py-20">
      <div className="container space-y-12">
        <Link to="/publications" className="flex gap-2 items-center">
          <img src={Icons.ic_back_nav} alt="" />
          <p className="uppercase text-primary text-sm font-semibold leading-[16.8px] tracking-[12%]">
            All Publications
          </p>
        </Link>

        <div className="space-y-16">
          <div className="bg-grey-50 border border-grey-200 px-4 py-16 md:px-[104px] space-y-12 max-w-[1008px] mx-auto">
            <div className="space-y-12">
              <div className="space-y-4 pb-12 border-b border-grey-300">
                <span className="uppercase block text-grey-500 uppercase !leading-[24px]">
                  {publicationItem?.publishedDate
                    ? format(
                        new Date(publicationItem?.publishedDate),
                        "MMMM dd, yyyy"
                      )
                    : ""}
                </span>
                <h2 className="uppercase text-black text-xl md:text-[32px] font-bold !leading-[32px] md:!leading-[48px]">
                  {publicationItem?.title}
                </h2>
                <p className="uppercase text-grey-500 text-base !leading-[24px]">
                  By{" "}
                  <span className="text-black">{publicationItem?.author}</span>
                </p>
              </div>
              <img
                src={publicationItem?.publicationImage?.image}
                className="object-cover w-full md:h-[452px]"
                alt=""
              />
              <div className="space-y-4 text-grey-600 text-base md:text-lg !leading-[32px] break-words">
                <PortableText
                  value={publicationItem?.content}
                  components={myPortableTextComponents}
                />
              </div>
            </div>
          </div>
          <div className="space-y-8">
            <p>RECENT PUBLICATIONS</p>
            {loadingRecentPublications ? (
              <div className="flex justify-center items-center">
                {Icons.ic_loading}
              </div>
            ) : (
              <div className="flex gap-[33px] overflow-x-auto justify-center">
                {recentPublications?.slice(0, 3)?.map((item) => (
                  <Card key={item._id} item={item} />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

import { useState } from "react";

const useAccordion = (isAccordionOpen) => {
  const [openIndex, setOpenIndex] = useState(isAccordionOpen ? 0:null);

  const toggleAccordion = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return { openIndex, toggleAccordion };
};

export default useAccordion;

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  A11y,
  EffectFade,
  Autoplay,
} from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";

const slides = [
  // {
  //   dividerText: "AKK FORUM 2024: Nigeria's Premier Gas Pipeline Event",
  //   tittle: "Gas to Prosperity: Catalysing Economic growth",
  //   buttonText: "Register to Attend",
  //   buttonLink: "http://akkforum.com",

  //   bannerImage: "bg-hero-banner5",
  // },
  // {
  //   dividerText: "Collaboration and Innovation",
  //   tittle:
  //     "GACN Collaborates with Norwegian Energy Partners (NORWEP) and Cabtree on an Africa Focused Panel at the ONS Conference 2024",
  //   buttonText: "View",
  //   buttonLink:
  //     "/news/gacn-collaborates-with-cabtree-and-norwep-norwegian-energy-partners-to-an-african-focused-panel-at-the-ons-conference-2024",
  //   bannerImage: "bg-hero-banner4",
  //   position: "md:relative md:bottom-[-165px] md:left-5",
  // },
  {
    dividerText: "OVER A DECADE",
    tittle:
      "Facilitating Nigeria's Natural Gas Revolution - Gas Aggregator Company Nigeria Ltd/Gte",
    buttonText: "Learn more about Us",
    buttonLink: "/about",
    bannerImage: "bg-hero-banner1",
  },
  {
    dividerText: "GACN's STRATEGIC IMPACT",
    tittle:
      "Fueling Nigeria’s Industrial and Commercial Growth by Facilitating Sustainable Gas  Supply",
    buttonText: "Explore Our Services",
    buttonLink: "/services",
    bannerImage: "bg-hero-banner2",
  },
  {
    dividerText: "ENGAGEMENT AND PARTNERSHIPS",
    tittle:
      "Providing an Industry Collaboration Platform for a Prosperous and Sustainable Energy",
    buttonText: "View news update",
    buttonLink: "/news",
    bannerImage: "bg-hero-banner3",
  },
];

const HeroBanner = ({
  dividerText,
  tittle,
  buttonText,
  buttonLink,
  bannerImage,
}) => (
  <div
    id="hero"
    className={`
    ${bannerImage} 
    bg-cover bg-no-repeat bg-center pt-[112px] md:pt-[219px] pb-[241px] md:pb-[194px] flex relative min-h-[745px] md:min-h-[1045px] xl:min-h-[845px] max-h-[745px] md:max-h-[1045px] xl:max-h-[845px] md:h-auto`}
  >
    <div className="bg-primary-900 absolute w-full h-full top-0 left-0 opacity-25"></div>
    <div className="container">
      <p className="mb-10 md:mb-12 pb-4 uppercase text-white text-lg sm:text-xl font-semibold tracking-[-0.4px] border-b-2 border-solid border-grey-200">
        {dividerText}
      </p>
      <h1 className="text-white text-[28px] md:text-[56px] font-bold tracking-[-0.56px] md:tracking-[-2.24px] pb-10 md:pb-16 lg:w-[85%]">
        {tittle}
      </h1>
      <Link to={buttonLink}>
        <button className="flex items-center justify-center gap-2 bg-hero-button border border-solid borde-white px-3 py-2 h-[56px] w-[266px] text-white text-sm uppercase tracking-[1.68px] font-semibold">
          <span>{buttonText}</span>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 256 256"
            className="w-4 h-4"
          >
            <path d="M181.66,133.66l-80,80a8,8,0,0,1-11.32-11.32L164.69,128,90.34,53.66a8,8,0,0,1,11.32-11.32l80,80A8,8,0,0,1,181.66,133.66Z"></path>
          </svg>
        </button>
      </Link>
    </div>
  </div>
);

const Hero = () => {
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
  const handleSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.activeIndex);
  };
  return (
    <section id="hero" className="hero relative">
      <Swiper
        slidesPerView={1}
        modules={[Navigation, Pagination, A11y, EffectFade, Autoplay]}
        navigation
        pagination={{ clickable: true, type: "progressbar" }}
        autoplay={{
          delay: activeSlideIndex === 0 ? 12000 : 5000,
          disableOnInteraction: false,
        }}
        effect="fade"
        onSlideChange={handleSlideChange}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <HeroBanner
              dividerText={slide.dividerText}
              tittle={slide.tittle}
              buttonText={slide.buttonText}
              buttonLink={slide.buttonLink}
              bannerImage={slide.bannerImage}
              position={slide?.position}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Hero;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import Icons from "../../assets/icons";
import Input from "../../common/input";
import Tab from "./tab";

export default function InnerBanner({
  tabs,
  activeTab,
  handleTabClick,
  searchText,
  handleSearch,
  fetchNews,
  newsTag,
}) {
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchNews(activeTab);
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchText]);

  return (
    <section className="bg-primary-900 pt-[80px] md:pt-[112px] pb-[114px] md:pb-[160px] flex items-center">
      <div className="container space-y-12">
        <h3 className="py-3 max-w-[508px] mx-auto text-white text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-primary-600">
          <span className="text-primary-200">/</span> LATEST NEWS & UPDATES
        </h3>
        <p className="capitalize text-white text-center text-base tracking-[-2%] max-w-[592px] mx-auto">
          Your source for the most recent developments and announcements from
          GACN
        </p>
        <div className="max-w-[798px] mx-auto">
          <Input
            type="search"
            name="search"
            id="search"
            placeholder="Search"
            icon={<img src={Icons.ic_search} alt="" />}
            onChange={(e) => handleSearch(e)}
          />
        </div>
        {newsTag && (
          <div>
            <h2 className="text-center text-white text-[32px] leading-[38.4px] pt-10">
              #{" "}
              <span className="font-bold capitalize">
                {newsTag?.toLowerCase()}
              </span>
            </h2>
          </div>
        )}
        {!searchText && !newsTag && (
          <Tab
            tabs={tabs}
            activeTab={activeTab}
            handleTabClick={handleTabClick}
          />
        )}
      </div>
    </section>
  );
}

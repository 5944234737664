import React from "react";
import Icons from "../../../assets/icons";
import Button from "../../../common/button";

import layerBlurGreen from "../../../assets/images/value/layer_blur_green.svg";
import layerBlurYellow from "../../../assets/images/value/layer_blur_yellow.svg";

const ValueCard = ({ title, description, icon }) => (
  <div className="flex flex-col items-center md:items-start gap-4 max-w-[326px] ">
    <img src={icon} width={24} height={24} alt="" />
    <div className="text-center md:text-left">
      <h4 className="text-black text-lg md:text-xl font-semibold !leading-[32px] tracking[-0.48px] pb-4">
        {title}
      </h4>
      <p className="text-grey-600 text-base md:text-lg">{description}</p>
    </div>
  </div>
);

const Value = ({ toggleContactModal }) => {
  return (
    <section id="value" className="relative">
      <img src={layerBlurYellow} className="absolute top-0 left-0" alt="" />
      <img src={layerBlurGreen} className="absolute bottom-0 right-0" alt="" />

      <div className="container">
        <div className="flex flex-col items-center gap-10 md:gap-[64px] pt-[80px] pb-[80px] md:pb-[104px]">
          <h3 className="text-black text-xl md:text-[28px] font-bold !leading-[33.6px] tracking[-0.56px] text-center ">
            POWERING PROGRESS,
            <br /> CREATING POSSIBILITIES
          </h3>
          <div className="flex flex-col md:flex-row justify-between gap-14 md:gap-[64px]">
            <ValueCard
              title="Price Aggregation Rules Management Systems"
              description="We build a robust management software in collaboration with the industry."
              icon={Icons.ic_naira}
            />
            <ValueCard
              title="Flexible Gas Supply Framework"
              description="GACN facilitates bespoke gas supply arrangements, adapting to customer demands and project-centric supply needs."
              icon={Icons.ic_chart}
            />
            <ValueCard
              title="99.9% Customer satisfaction"
              description="Our built PARMS helps you manage gas revenues and administer aggregation"
              icon={Icons.ic_like}
            />
          </div>
          <Button onClick={() => toggleContactModal(true)}>Contact Us</Button>
        </div>
      </div>
    </section>
  );
};

export default Value;

import React from "react";

import Layout from "../components/layout";
import Publications from "../components/publications";

const PublicationsPage = () => {
  return (
    <Layout>
      <Publications />
    </Layout>
  );
};

export default PublicationsPage;

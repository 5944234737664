import React from "react";

import Layout from "../components/layout";
import EnergyTransitionAccordion from "../components/energyTransition/accrodion";
import EnergyTransitionNavigation from "../components/energyTransition/navigation";
import InnerBanner from "../common/innerBanner";

const EnergyTransitionPage = () => {
  return (
    <Layout contact={true}>
      <InnerBanner
        name="big"
        title="GACN’s Commitment to Energy Transition "
        description="Leading the shift towards Decarbonization with Natural Gas "
        bannerImage="bg-energy-banner"
      />

      <EnergyTransitionNavigation />
      <EnergyTransitionAccordion />
    </Layout>
  );
};

export default EnergyTransitionPage;

import Icons from "../../../assets/icons";
import useBiographyModal from "../../../hooks/useBiographyModal";

export default function Card({ item }) {
  const { image, name, role, link } = item;

  const { toggleBiographyModal, setBiography } = useBiographyModal();

  return (
    <div className="border border-grey-300">
      <img src={image} alt={name} />
      <div className="space-y-3 bg-grey-10 py-3 px-4">
        <div className="flex justify-between items-center">
          <h6 className="uppercase text-black text-xl font-medium !leading-[32px] tracking-[-2%] ">
            {name}
          </h6>
          {link && (
            <a href={link} target="_blank" rel="noreferrer">
              <img src={Icons.ic_linkedin_green} alt="" />
            </a>
          )}
        </div>
        <p className="text-grey-600 text-sm !leading-[20.3px] min-h-[40px] inline-flex items-end">
          {role}
        </p>
        <p
          onClick={() => {
            setBiography(item);
            toggleBiographyModal(true);
          }}
          className="cursor-pointer uppercase text-xs text-grey-500 !leading-[17.4px] border-t border-grey-300 pt-3 flex gap-[10px] items-center justify-end"
        >
          <span>more info</span> <img src={Icons.ic_right} alt="" />
        </p>
      </div>
    </div>
  );
}

import React from "react";
import GeneralAccordion from "../../../common/accordion/generalAccordion";

const accordionData = [
  {
    question: 'TMD Platform Africa Limited ("TMD") collaboration',
    answer:
      'Aligned with our mandate to promote gas commercialization, enhance gas penetration, and catalyze gas-driven industrialization, GACN is leveraging its expertise in the gas industry to support TMD Platform Africa Limited ("TMD"). Our collaboration aims to facilitate the smooth trading of natural gas, promote transparency, and foster sustainable energy access across Africa.',
  },
  {
    question: "Nigerian Gas Association (NGA) Cooperation",
    answer:
      "Our cooperation with the NGA involves developing a common stance on critical industry issues, supporting various GACN programs, and actively participating in NGA activities. This synergy is crucial for addressing challenges and advancing the gas sector.",
  },
  {
    question: "Nigeria Export Processing Zones Authority (NEPZA) Collaboration",
    answer:
      "Collaborating with NEPZA, we focus on addressing the gas and power needs of Free Trade Zones and Industrial Parks. This partnership is key to facilitating energy solutions in these critical economic zones.",
  },
  {
    question: "Nigerian Railway Corporation (NRC) Partnership",
    answer:
      "Our innovative partnership with the NRC explores using railway networks for transporting LNG and CNG. This includes proposals to convert railway engines to dual fuel, harnessing the potential of gas as a primary energy source.",
  },
  {
    question: "Nigerian Bulk Electricity Trading Plc (NBET) Alignment",
    answer:
      "Working closely with NBET, we align on areas of mutual value like centralized gas supply contract management and strategies to mitigate gas price-related liabilities, contributing significantly to the gas-to-power value chain",
  },
  {
    question: "NNPC Gas & Power Investment Services (NGPIS) Collaboration",
    answer:
      "Focused on leveraging investment opportunities in Nigeria's gas and power sectors, this partnership aims to expand gas supply, boost power generation, and support Nigeria's ambition in the global energy market. It enhances energy security and promotes sustainable economic growth.",
  },
];

export default function PartnershipsAccordion() {
  return (
    <section className="container !py-10 md:!py-20">
      <GeneralAccordion accordionData={accordionData} type='partnership' />
    </section>
  );
}

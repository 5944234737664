import React from "react";
import ServicesAccordion from "../../common/accordion/servicesAccordion";
import Navigation from "./navigation";

export default function ServicesInfo() {
  return (
    <section className="pt-10 pb-20 md:py-20 ">
      <div className="container space-y-16">
        <ServicesAccordion />
        <Navigation />
      </div>
    </section>
  );
}

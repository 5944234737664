import React from "react";

import WhatWeDoAccordion from "../../../common/accordion/whatWeDoAccordion";
import { Link } from "react-router-dom";
import Button from "../../../common/button";

export default function WhatWeDo() {
  return (
    <section className="bg-primary-50 py-20">
      <div className="container">
        <h3 className="z-10 mb-10 md:mb-16 py-3 text-black text-xl md:text-[28px] font-bold tracking-[-0.56px] border-y border-solid border-grey-500 ">
          <span className="text-primary-200">/</span> WHAT WE DO
        </h3>
        <WhatWeDoAccordion />
        <div className="flex pt-16">
          <Link to="/services" className="block mx-auto">
            <Button className="mx-auto">View all Services</Button>
          </Link>
        </div>
      </div>
    </section>
  );
}

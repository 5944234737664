import React from "react";
import Icons from "../../../assets/icons";

const ValueCard = ({ title, description, icon }) => (
  <div className="flex flex-col items-center md:items-start gap-4 max-w-[312px] ">
    {icon}
    <div className="text-center md:text-left">
      <h4 className="text-black text-lg md:text-xl font-semibold !leading-[32px] tracking[-0.48px] pb-4">
        {title}
      </h4>
      <p className="text-grey-600 text-base md:text-lg">{description}</p>
    </div>
  </div>
);

const Vision = () => {
  return (
    <section>
      <div className="container">
        <div className="flex flex-col items-center gap-10 md:gap-[94px] py-[80px] md:pb-[204px]">
          <h3 className="text-black text-xl md:text-[28px] font-bold !leading-[33.6px] tracking[-0.56px] text-center ">
            "Pioneering Sustainable Gas Solutions for Nigeria"
          </h3>
          <div className="flex flex-col md:flex-row justify-between gap-14 md:gap-[64px]">
            <ValueCard
              title="Vision"
              description="Driving natural gas commercialization and utilization in Nigeria to create sustainable value for our stakeholders and the Nation."
              icon={Icons.ic_vision}
            />
            <ValueCard
              title="Values"
              description="Transparency, Innovation, Excellence, Commitment, Teamwork."
              icon={Icons.ic_values}
            />

            <ValueCard
              title="Mission"
              description="To be the premier platform for stakeholders seeking sustainable services and transformative partnerships in the gas value chain."
              icon={Icons.ic_mission}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;

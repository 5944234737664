import urlBuilder from "@sanity/image-url";
import { useEffect, useState } from "react";
import { client } from "../sanity";

const SampleImageComponent = ({ value, isInline }) => {
  return (
    <img
      src={urlBuilder()
        .image(value)
        .projectId(process.env.REACT_APP_SANITY_PROJECT_ID)
        .dataset(process.env.REACT_APP_SANITY_DATASET)
        .width(isInline ? 100 : 800)
        .fit("max")
        .auto("format")
        .url()}
      alt={value.alt || " "}
      loading="lazy"
      style={{
        display: isInline ? "inline-block" : "block",
      }}
    />
  );
};

const PdfDownloadComponent = ({ value }) => {
  const [pdfUrl, setPdfUrl] = useState("");

  useEffect(() => {
    client.getDocument(value.asset._ref).then((data) => {
      const pdfUrl = data.url;
      setPdfUrl(pdfUrl);
    });
  }, [value.asset._ref]);

  return (
    <a
      href={pdfUrl}
      className="block !mt-10 text-sm uppercase font-semibold tracking-[1.68px] rounded-lg py-4 px-4 bg-primary text-white text-center w-[220px]"
      download
    >
      Download PDF
    </a>
  );
};

export const myPortableTextComponents = {
  types: {
    image: SampleImageComponent,
    file: PdfDownloadComponent,
  },
  listItem: {
    bullet: ({ children }) => (
      <li style={{ listStyleType: "disc", marginLeft: "16px" }}>{children}</li>
    ),
    number: ({ children }) => (
      <li style={{ listStyleType: "number", marginLeft: "20px" }}>
        {children}
      </li>
    ),
  },
  marks: {
    link: ({ children, value }) => {
      const rel = !value.href.startsWith("/")
        ? "noreferrer noopener"
        : undefined;
      return (
        <a href={value.href} className="text-primary" rel={rel}>
          {children}
        </a>
      );
    },
  },
};

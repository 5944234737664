import React from "react";
import Layout from "../components/layout";
import NotFound from "../components/notFound";

export default function NotFoundPage() {
  return (
    <Layout footer={true}>
      <NotFound />
    </Layout>
  );
}

import { useContext } from "react";
import { ContactModal } from "../context/contactModalContext";

const useContactModal = () => {
  const { isContactModalOpen, dispatch } = useContext(ContactModal);

  const toggleContactModal = (value) => {
    dispatch({
      type: "TOGGLE",
      payload: {
        isContactModalOpen: value,
      },
    });
  };

  return {
    toggleContactModal,
    isContactModalOpen,
  };
};

export default useContactModal;

import React from "react";

export default function Card({ stepNumber, icon, title, description }) {
  return (
    <div className="space-y-6 relative pl-[38px] sm:pl-[46px] before:absolute before:top-0 before:left-0 before:w-[22px] before:h-[22px] before:border-2 before:border-primary before:rounded-full before:bg-primary-50 after:absolute after:top-[34px] after:left-[10px] after:w-[1px] after:h-[70%] sm:after:h-[77%] after:bg-primary-75">
      <p className="text-grey-400 leading-[23.2px]">STEP {stepNumber}</p>
      <div className="space-y-4">
        <img src={icon} alt="" />
        <div className="space-y-3 !leading-[32px] tracking-[-2%]">
          <h6 className="text-black text-lg md:text-xl font-semibold">
            {title}
          </h6>
          <p className="text-grey-500 text-base md:text-lg hidden sm:block">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

import ReactPaginate from "react-paginate";
import Icons from "../assets/icons";

export default function Pagination({ pageCount, handlePageClick, children }) {
  
  return (
    <>
      {children}
      <ReactPaginate
        previousLabel={
          <img src={Icons.ic_next} className="rotate-180" alt="" />
        }
        nextLabel={<img src={Icons.ic_next} alt="" />}
        breakLabel="..."
        onPageChange={handlePageClick}
        pageRangeDisplayed={5}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        containerClassName="!mt-16 !pb-16 md:!pb-0 flex items-center justify-center gap-[2px] !leading-[20.3px] text-grey-400 text-sm"
        pageClassName="h-9 w-9 bg-white rounded-lg flex items-center justify-center"
        previousClassName="mr-[6px] h-9 w-9 bg-white rounded-full flex items-center justify-center"
        nextClassName="ml-[6px] h-9 w-9 bg-white rounded-full flex items-center justify-center"
        breakClassName="h-9 w-9 bg-white rounded-full flex items-center justify-center"
        activeClassName="border border-primary"
        activeLinkClassName="text-black"
        disabledClassName="hidden"
      />
    </>
  );
}

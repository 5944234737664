import React from "react";

import Layout from "../components/layout";
import PublicationsDetail from "../components/publications/details";

const PublicationsDetailsPage = () => {
  return (
    <Layout>
      <PublicationsDetail />
    </Layout>
  );
};

export default PublicationsDetailsPage;

import { createBrowserRouter } from "react-router-dom";
import HomePage from "../pages/home";
import AboutPage from "../pages/about";
import ManagementPage from "../pages/management";
import ServicesPage from "../pages/services";
import PartnershipsPage from "../pages/partnerships";
import InitiativesPage from "../pages/initiatives";
import EnergyTransitionPage from "../pages/energyTransition";
import CodeOfConductPage from "../pages/codeOfConduct";
import FAQPage from "../pages/faq";
import TermsAndConditionPage from "../pages/termsAndCondition";
import PurchaseProcessPage from "../pages/purchaseProcess";
import NotFoundPage from "../pages/notFound";
import NewsPage from "../pages/news";
import NewsDetailsPage from "../pages/newsDetail";
import PublicationsPage from "../pages/publications";
import PublicationsDetailsPage from "../pages/publicationsDetail";
import GalleryPage from "../pages/gallery";
// import BrandingPage from "../pages/branding";
import GalleryDetailPage from "../pages/galleryDetail";
import TransparencyAndAccountabilityPage from "../pages/whistleblowerPolicy";


const routes = createBrowserRouter([
  {
    path: "/",
    exact: true,
    element: <HomePage />,
  },
  {
    path: "/about",
    exact: true,
    element: <AboutPage />,
  },
  {
    path: "/management",
    exact: true,
    element: <ManagementPage />,
  },
  {
    path: "/code-of-conduct",
    exact: true,
    element: <CodeOfConductPage />,
  },
  {
    path: "/services",
    exact: true,
    element: <ServicesPage />,
  },
  {
    path: "/initiatives",
    exact: true,
    element: <InitiativesPage />,
  },
  {
    path: "/partnerships",
    exact: true,
    element: <PartnershipsPage />,
  },
  {
    path: "/energy-transition",
    exact: true,
    element: <EnergyTransitionPage />,
  },
  {
    path: "/whistleblower-policy",
    exact: true,
    element: <TransparencyAndAccountabilityPage />,
  },
  {
    path: "/purchase-process",
    exact: true,
    element: <PurchaseProcessPage />,
  },
  {
    path: "/news",
    exact: true,
    element: <NewsPage />,
  },
  {
    path: "/news/:slug",
    exact: true,
    element: <NewsDetailsPage />,
  },
  {
    path: "/publications",
    exact: true,
    element: <PublicationsPage />,
  },
  {
    path: "/publications/:slug",
    exact: true,
    element: <PublicationsDetailsPage />,
  },
  {
    path: "/gallery",
    exact: true,
    element: <GalleryPage />,
  },
  {
    path: "/gallery/:slug",
    exact: true,
    element: <GalleryDetailPage />,
  },
  // {
  //   path: "/branding",
  //   exact: true,
  //   element: <BrandingPage />,
  // },
  // ..
  {
    path: "/faq",
    exact: true,
    element: <FAQPage />,
  },
  {
    path: "/terms-and-conditions",
    exact: true,
    element: <TermsAndConditionPage />,
  },

  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

export default routes;

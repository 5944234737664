import React from "react";
import GeneralAccordion from "../../../common/accordion/generalAccordion";
import image1 from "../../../assets/images/services/energy_transition.jpeg";

const accordionData = [
  {
    question: "The Role of Natural Gas in Energy Transition",
    answer:
      "The role of natural gas in the energy transition is multifaceted and crucial for achieving a more sustainable and low-carbon future. Natural gas contributes significantly to decarbonization by providing emissions reductions through fuel substitution, emitting significantly less carbon dioxide than heavier emitting fuels and facilitate the development of cleaner energy technologies, making it a critical component of the energy transition.",
  },
  {
    question: "The Concept of Energy Transition",
    answer:
      "The energy transition is a comprehensive process that incorporates changes in energy supply, demand, infrastructure, and policy to produce a more sustainable, low-carbon energy system.",
  },
  {
    question: "Introduction to the Emission Challenge",
    answer:
      "The energy sector's contribution to global emissions rose from 66% in 1990 to 73% in 2016, with Africa accounting for 2-3% of these emissions. The emission challenge encompasses the urgent need to address carbon emissions, particularly in the context of the energy transition and sustainability efforts. This challenge involves reducing greenhouse gas emissions, with a focus on carbon dioxide, to mitigate climate change and achieve global climate goals.  ",
  },
];

export default function EnergyTransitionNavigation() {
  return (
    <section className="py-20 bg-primary-50">
      <div className="container">
        <h3 className="z-10 mb-10 md:mb-16 py-3 text-black text-xl md:text-[28px] font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span> NAVIGATING THE ENERGY
          TRANSITION: A SUSTAINABLE FUTURE
        </h3>
        <img src={image1} alt="" className="h-[328px] w-full object-cover" />

        <GeneralAccordion accordionData={accordionData} type="none" />
      </div>
    </section>
  );
}

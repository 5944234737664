import React from "react";

import DesktopNavbar from "./desktopNav";
import MobileNavbar from "./mobileNav";

const Navbar = ({ toggleContactModal, isContactModalOpen, toggleShowForm }) => {
  return (
    <>
      <DesktopNavbar
        toggleContactModal={toggleContactModal}
        isContactModalOpen={isContactModalOpen}
        toggleShowForm={toggleShowForm}
      />
      <MobileNavbar
        toggleContactModal={toggleContactModal}
        isContactModalOpen={isContactModalOpen}
        toggleShowForm={toggleShowForm}
      />
    </>
  );
};

export default Navbar;

import React from "react";

const Input = (props) => {
  const { className, name, title, icon, errors, ...rest } = props;

  return (
    <div className={className}>
      {title && (
        <label className="text-grey-900 text-sm font-medium  mb-1 block">
          {title}
        </label>
      )}

      <div className="relative">
        <input
          className={`bg-white p-4 w-full appearance-none border focus:outline-none focus:border-2 focus:border-primary border-grey-300 text-grey-900 rounded-md transition duration-150 ease-in-out
          ${name === "search" ?'pl-11':''}`}
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          {...rest}
        />

        {name === "search" ? (
          <div className="absolute left-4 bottom-[19px] text-grey-500">
            {icon}
          </div>
        ) : (
          icon && (
            <div className="absolute right-4 bottom-[19px] text-grey-500">
              {icon}
            </div>
          )
        )}
      </div>
      {errors && <p className="mt-2 text-xs text-error">{errors}</p>}
    </div>
  );
};

export default Input;

import React from "react";

import Layout from "../components/layout";
import Members from "../components/members";
import InitiativesAccordion from "../components/initiatives/accrodion";
import InnerBanner from "../common/innerBanner";

const InitiativesPage = () => {
  return (
    <Layout contact={true}>
      <InnerBanner
        name="small"
        title="GACN INITIATIVES"
        description="At GACN, innovation is key. We're leading change in Nigeria's gas sector with initiatives aimed at efficiency, market expansion, and sustainable development."
        bannerImage="bg-initiatives-bannerMobile md:bg-initiatives-bannerDesktop"
      />
      <InitiativesAccordion />
      <Members />
    </Layout>
  );
};

export default InitiativesPage;

import React from "react";
import Icons from "../../../assets/icons";

const ValueCard = ({ title, description, icon }) => (
  <div className="flex flex-col items-center md:items-start gap-4 max-w-[312px] ">
    {icon}
    <div className="text-center md:text-left">
      <h4 className="text-black text-lg md:text-xl font-semibold !leading-[32px] tracking[-0.48px] pb-4">
        {title}
      </h4>
      <p className="text-grey-600 text-base md:text-lg">{description}</p>
    </div>
  </div>
);

const WhistleblowerPolicyTrustOurProcess = () => {
  return (
    <section>
      <div className="container">
        <div className="flex flex-col items-center gap-10 md:gap-[94px] py-[80px] md:pb-[204px]">
          <h3 className="text-black text-xl md:text-[28px] font-bold !leading-[33.6px] tracking[-0.56px] text-center ">
            Why You Can Trust Our Process
          </h3>
          <div className="flex flex-col md:flex-row justify-between gap-14 md:gap-[64px]">
            <ValueCard
              title="Anonymity"
              description="Reports can be made without revealing your identity."
              icon={Icons.ic_vision}
            />
            <ValueCard
              title="Security"
              description="Strong encryption and restricted access protect your data."
              icon={Icons.ic_values}
            />

            <ValueCard
              title="Non-Retaliation Policy"
              description="GACN ensures all whistleblowers are protected from retaliation."
              icon={Icons.ic_mission}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhistleblowerPolicyTrustOurProcess;

import React from "react";
import Button from "../../common/button";
import Icons from "../../assets/icons";

function FormSuccess({ toggleContactModal, toggleShowForm }) {
  return (
    <div className="h-full flex justify-center items-center mt-[34px]">
      <div className=" mx-auto flex flex-col gap-12">
        <div className="flex flex-col items-center gap-6">
          <div className="w-14 h-14 relative bg-primary-50 rounded-full flex justify-center items-center">
            {Icons.ic_success}
          </div>
          <div className="text-center space-y-4">
            <h3 className="text-grey-800 text-2xl font-semibold !leading-[28.8px]">
              Thanks for reaching out!
            </h3>
            <p className="text-grey-500">Our team will contact your shortly</p>
          </div>
        </div>
        <Button
          height='h-48px'
          onClick={() => {
            toggleContactModal(false);
            toggleShowForm(true);
          }}
          fullWidth={true}
        >
          BACK TO HOME
        </Button>
      </div>
    </div>
  );
}

export default FormSuccess;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Card from "./card";

import Pagination from "../../common/pagination";
import usePagination from "../../hooks/usePagination";
import useFetchSanityData from "../../hooks/useFetchSanityData";
import Icons from "../../assets/icons";

export default function Gallery() {
  const { gallery, loadingGallery, fetchGallery } = useFetchSanityData();
  const { currentItems, pageCount, handlePageClick } = usePagination(
    gallery,
    12
  );

  useEffect(() => {
    fetchGallery();
  }, []);

  return (
    <section className="pb-20 mt-[-34px] md:mt-[-80px]">
      {loadingGallery ? (
        <div className="pt-[128px] pb-[48px] flex justify-center items-center bg-white text-center">
          {Icons.ic_loading}
        </div>
      ) : (
        <div className="container">
          <div className="bg-grey-50 border border-grey-200 px-6 py-12 md:p-12 space-y-2 max-w-[1216px] mx-auto">
            <Pagination
              currentItems={currentItems}
              pageCount={pageCount}
              handlePageClick={handlePageClick}
            >
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                {currentItems &&
                  currentItems?.map((item) => (
                    <Card key={item?._id} item={item} />
                  ))}
              </div>
            </Pagination>
          </div>
        </div>
      )}
    </section>
  );
}

import React from "react";

export default function Card({ item }) {
  const { image } = item;
  return (
    image && (
      <div className="min-w-full overflow-hidden">
        <img
          src={image}
          className="w-[311px] md:w-[352px] h-[321px] md:h-[417px] object-cover object-center mx-auto transition-all hover:scale-110"
          alt=""
        />
      </div>
    )
  );
}

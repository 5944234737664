/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

import Icons from "../../assets/icons";
import Input from "../../common/input";

export default function InnerBanner({
  searchText,
  handleSearch,
  fetchPublication,
}) {
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchPublication();
    }, 500);

    return () => clearTimeout(debounceTimeout);
  }, [searchText]);

  return (
    <section className="bg-primary-900 pt-[80px] md:pt-[112px] pb-[114px] md:pb-[160px] flex items-center">
      <div className="container space-y-12">
        <h3 className="py-3 max-w-[508px] mx-auto text-white text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-primary-600">
          <span className="text-primary-200">/</span> GACN PUBLICATIONS
        </h3>
        <p className="capitalize text-white text-center text-base tracking-[-2%] max-w-[592px] mx-auto">
          Explore our comprehensive collection of resources, offering in-depth
          insights into the gas sector
        </p>
        <div className="max-w-[798px] mx-auto">
          <Input
            type="search"
            name="search"
            id="search"
            placeholder="Search"
            icon={<img src={Icons.ic_search} alt="" />}
            onChange={(e) => handleSearch(e)}
          />
        </div>
      </div>
    </section>
  );
}

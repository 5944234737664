import React from "react";

import image1 from "../../../assets/images/management/1.png";
import image2 from "../../../assets/images/management/2.png";
import image3 from "../../../assets/images/management/3.png";
import image4 from "../../../assets/images/management/4.png";
import image5 from "../../../assets/images/management/5.png";
import image6 from "../../../assets/images/management/6.png";
import image8 from "../../../assets/images/management/8.png";
import image9 from "../../../assets/images/management/9.png";
import image10 from "../../../assets/images/management/10.png";
import image11 from "../../../assets/images/management/11.png";
import image12 from "../../../assets/images/management/12.png";
import Card from "./card";
import BiographyModal from "../biographyModal";

const team = [
  {
    image: image1,
    name: "CHIJIOKE UZOHO",
    role: "Managing Director & CEO",
    link: "https://www.linkedin.com/in/chijioke-uzoho-2b787875/",
    details:
      "Chijioke Uzoho is a seasoned energy professional with about two decades of extensive experience in the oil and gas industry. Following his secondment from the Nigerian National Petroleum Company Limited (NNPCL) Chijioke serves as the Managing Director and Chief Executive Officer of Gas Aggregator Company Nigeria Ltd/Gte (GACN), leading GACN in its strategic direction to promote domestic gas utilization and foster gas-driven economic development./n As GACN's Managing Director, he plays a pivotal role in guiding the company through the implementation of its expanded mandates subsequent to the Petroleum Industry Act, 2021. /n With his strategic business development and strategic leadership skills, Mr. Uzoho is passionate about driving business growth, building strategic partnerships, and delivering value to customers and stakeholders in the Nigerian gas sector and by extension, the Nigerian economy. /n Prior to his appointment, Mr. Uzoho was the General Manager, Commercial in GACN where he led the commercial team in developing and implementing strategic initiatives to optimize revenue generation, market expansion, and gas penetration in the Nigerian gas market. /n He was instrumental in steering market-driven solutions, overseeing critical commercial aspects such as Gas sourcing, upstream gas commercialization, gas trading, business development, and contract negotiations.",
  },
  {
    image: image2,
    name: "YETUNDE ODEJOBI",
    role: "General Manager, Operations",
    link: "",
    details:
      "Yetunde Odejobi is a seasoned executive with 20 years cognate experience across the oil and gas value chain. She currently serves as a secondee from Total Energies EP Nigeria Limited (Total Energies), in the capacity of the General Manager – Operations, GACN where she leads the national gas supply/demand allocation process by filtering Gas Requests from prospective Buyers via the statutory Due Diligence Assessment, the pivotal and first step in the GACN Gas Purchase process. She also oversees the critical activity of effective delivery of all Information Communication Technology (ICT) services and the performance of the Price Aggregation Rules Management System (PARMS) software which facilitates disbursement of revenue for supply of gas under the GACN established Escrow. In this strategic role, she brings her exceptional expertise to bear as the Regulatory Interface for GACN in all engagements related to the Domestic Gas Demand Requirement (DGDR) and Domestic Gas Delivery Obligation (DGDO) in tandem with the Regulators (NMDPRA and NUPRC). /n Prior to her current role in GACN, she has held various senior positions in which span Joint Venture Management, Export Gas, Domestic Gas and Crude Oil Commercial departments. Her foray into the domestic gas market commenced when she was appointed as one of the pioneer members of the novel “Gas Task Force” of TotalEnergies in 2008 during the evolution of the Federal Government intervention in the domestic gas sub-sector and implementation of targeted policies to promote domestic gas utilization and industrialization such as the Nigerian Gas Masterplan, National Gas Supply and Pricing Regulations , National Gas Policy and National Gas Transportation Network Code, amongst others. Yetunde is actively lending her voice in the advocacy for a just, equitable and sustainable energy transition in Nigeria.",
  },
  {
    image: image3,
    name: "TAYO RHODES-VIVOUR",
    role: "General Manager, Corporate Secretariat & Legal Services",
    link: "https://www.linkedin.com/in/omotayo-rhodes-vivour-3b684663/",
    details:
      "Tayo Rhodes-Vivour brings extensive legal expertise to her role as General Manager, Corporate Secretariat & Legal Advisor, with a proven track record in various facets of the legal services industry, including arbitration and a deep understanding of legal contracting requirements within major project activities across the natural gas value-chain. /n As the General Manager, Corporate Secretariat & Legal Services at Gas Aggregator Company Nigeria Ltd/Gte (GACN), Tayo spearheads company secretarial duties for the Board of Directors while serving as the chief legal adviser of GACN. She is actively leading the restructuring efforts of GACN in alignment with the implementation of the Petroleum Industry Act 2021 (PIA), demonstrating her ability to navigate complex regulatory landscapes and drive strategic initiatives. /n Tayo Rhodes-Vivour is a strategic thinker who meticulously maps out long-term objectives and develops comprehensive plans aligning legal strategies with broader business goals at GACN. As a staunch advocate for GACN's interests within the industry, her persuasive advocacy skills drive favorable outcomes in negotiations, disputes, and regulatory matters, safeguarding the company's position and fostering its growth. Tayo's expertise in regulatory matters ensures GACN's compliance with evolving laws and regulations while maximizing opportunities within the regulatory framework. She plays a pivotal role in shaping GACN's governance structure, promoting transparency, accountability, and ethical conduct, and her proactive risk management and compliance efforts safeguard the company's interests, ensuring its long-term viability amidst industry challenges. /n Tayo Rhodes-Vivour's multifaceted skill set, coupled with her strategic mindset and comprehensive understanding of legal and regulatory frameworks, makes her an invaluable asset to GACN and a driving force in shaping its future success in the dynamic energy sector.",
  },
  {
    image: image4,
    name: "OMOSIGHO EGBON",
    role: "General Manager, Corporate Services",
    link: "https://www.linkedin.com/in/omosigho-egbon-05064a166/",
    details:
      "Omosigho is an accomplished Human Resources (HR) expert with over a decade worth of experience in the Oil & Gas Industry. With a proven track record of driving organizational excellence,streamlining operations, implementing effective strategies, maximizing efficiency and delivering exceptional results, her expertise spans across Operational efficiecncy, stakeholder engagement, Talent development business development, , strategy development, and administration. She oversees the strategic direction and operations of the organisation including developing systems, processes and infrastructure required to support the growth and future of the organisation. /n Omosigho exemplifies strong leadership skills, combining a collaborative and empowering approach with a keen business acumen. She fosters a positive and inclusive work culture, encourageing open communication, teamwork, and professional development. /n In recent past, she has successfully streamlined corporate services processes, reducing costs without compromising service quality, also developed and executed a comprehensive training program for employees, resulting in an increase in overall skill level and productivity.",
  },
  {
    image: image5,
    name: "OLAWALE OLALUWOYE",
    role: "General Manager, Finance",
    link: "https://www.linkedin.com/in/olawale-olaluwoye-b91a0220a/",
    details:
      "Wale is a highly accomplished professional who has achieved notable success in various roles during his extensive career spanning over 2 decades. As a Fellow of both the Institute of Chartered Accountants of Nigeria and the Chartered Institute of Taxation of Nigeria (CITN), he has demonstrated his expertise in the financial and taxation fields. Over the past two decades, Wale has made significant contributions to renowned organizations, showcasing his financial acumen and leadership skills. /n His career began in the foreign operations (international banking) units of First Bank of Nigeria and Zenith Bank of Nigeria Plc, where he gained invaluable experience and honed his financial expertise. Subsequently, he transitioned to Chevron Nigeria Ltd, where he held the role of Senior Finance Analyst and played pivotal roles in supporting various assets, including the Agbami Deepwater Development and Operation (including other Deepwater Assets), the Escravos Gas-To-Liquid facility, and the West African Gas Pipeline Company Limited. /n His contributions in these capacities significantly impacted the financial success and strategic direction of these ventures. He served as the Head of Investment at Chevron Nigeria Closed PFA, where he managed investment portfolios and contributed to the financial well-being of both retirees and employees . His dedication to the well-being of colleagues also led him to take on the role of Treasurer of the Chevron Employee Multi-Purpose Cooperative Society, where he played a vital part in promoting financial security and cooperative development. Wale's impressive career journey culminated in his secondment to the Gas Aggregator Company Nigeria Limited (GACN) as the shareholder's representative on the Company's Management. /n In this pivotal role, he brings his wealth of experience, financial expertise, and leadership skills to contribute to GACN's success and strategic objectives.",
  },
  {
    image: image6,
    name: "MOHAMMED SANI BELLO",
    role: "General Manager, Commercial",
    link: "",
    details:
      "Mohammed Sani Bello, an accomplished and results-oriented leader with an extensive background in the oil and gas industry, serves as the General Manager - Commercial for GACN. With a proven track record of driving business growth, optimizing commercial strategies, and fostering sustainable partnerships, Mr. Bello brings a wealth of experience to our dynamic leadership team. /n He has amassed over 15 years of experience in the oil and gas sector, demonstrating expertise in various facets of commercial management, particularly in NNPCL where he was involved in successful operations of offshore processing of Crude Oil for Products Exchange, monitoring of refinery operations to provide energy security, negotiating of LPG-related agreements on behalf of NNPC Trading Limited, and participating in PPMC/Duke Oil Business Model of profit/loss sharing resulting in more than N30 Billion profits. Graduating with a Chemical Engineering degree from London South Bank, University, Mr. Bello embarked on a career marked by continuous achievements and notable milestones.",
  },
  // {
  //   image: image7,
  //   name: "RUQAIYAH SAIDU",
  //   role: "Manager, Public Affairs",
  //   link: "https://www.linkedin.com/in/ruqaiyah-saidu-a7346a25a/",
  //   details:
  //     "Omosigho is an accomplished Human Resources (HR) expert with over a decade worth of experience in the Oil & Gas Industry. With a proven track record of excellence in streamlining operations, implementing effective strategies, and delivering exceptional results, her expertise spans across business development, HR, strategy development, and administration. She oversees the strategic direction and operations of the organisation including developing systems, processes and infrastructure required to support the growth and future of the organisation. /n In recent past, she has successfully streamlined corporate services processes, reducing costs without compromising service quality, also developed and executed a comprehensive training program for employees, resulting in an increase in overall skill level and productivity.",
  // },
  {
    image: image8,
    name: "IPADEOLA EGUNDEYI",
    role: "Manager, Internal Audit",
    link: "https://www.linkedin.com/in/ipadeola-egundeyi-9920ba80/",
    details:
      "Ipadeola, is a distinguished professional holding the titles of a Chartered Accountant and Fellow of the Chartered Institute of Bankers of Nigeria. His educational background includes a Bachelor of Science (BSc) degree in Banking and Finance, a Master of Business Administration (MBA) with a specialization in Financial Management, and a Master of Science (MSc) in Finance. With over three decades of work experience, his career has been dedicated to the Banking and Oil and Gas sectors.",
  },
  {
    image: image9,
    name: "ADELEKE OSIBAJO",
    role: "Manager, HR",
    link: "",
    details:
      "Adeleke Osibajo is a highly experienced Human Resources Manager with a robust track record in the Oil and Gas industry spanning over a decade, encompassing both commercial and operational functions. His skill set covers a wide range of HR areas, including Talent Management, Employee Relations, Benefits Administration, Performance Management, and policy implementation. He has a strong educational foundation with a Bachelor of Arts in Linguistics and an MBA. In addition to his academic qualifications, he has completed a Diploma in Human Resource Management and holds a Project Management certificate, indicating his commitment to professional development. As a member of the Chartered Institute of Personnel Management (CIPM), he underscores his dedication to excellence in the HR field. Adeleke's diverse skill set, educational drive, and extensive industry expertise position him as a valuable asset for any organisation, particularly in the Oil and Gas sector.",
  },
  {
    image: image10,
    name: "OCHE AGBESE",
    role: "Manager, Gas Trading and Network Code ",
    link: "https://www.linkedin.com/in/agbese-oche-3166aa25/",
    details:
      "Oche is a multifaceted professional with a diverse career spanning information technology, education development, and the natural gas sectors, reflecting his strong technical expertise and commitment to environmental sustainability. His early experience in IT equipped him with problem-solving and innovative skills, valuable for his transition into education development, where he empowered the next generation. Later, in the natural gas sector, he applied his technical proficiency to foster innovative approaches in deepening the Nigerian domestic gas market. Currently, as the Head of Trading & Network Code at GACN, he oversees various units to ensure increased gas commercialisation and promotes the understanding and implementation of the Nigerian Gas Transportation Network Code, all while advocating for environmental stewardship and sustainable practices to mitigate carbon emissions for a greener future.",
  },
  {
    image: image11,
    name: "BABARINDE JOHN",
    role: "Manager, Accounting & Financial Services",
    link: "",
    details:
      "Babarinde is an astute Banker, a Professional Accountant and Forensic expert with cognate experience spanning two decades. He is a member (Fellow and Associate) of multiple professional bodies including the Chartered Institute of Taxation of Nigeria (CITN), Association of National Accountants of Nigeria, ANAN (Associate), Certified Forensic Investigation Professionals, CFIP (Fellow), Chartered Institute of Finance and Control of Nigeria, CIFC (Fellow), Nigerian Institute of Management, NIM (Associate), Institute of Chartered Economics, ACE (Associate) among others. /n Babarinde currently oversees and coordinates the activities of the Finance department of GACN ensuring maintenance of an effective financial management framework that supports the company’s mission and business objectives and enables the effortless production of relevant periodic reports, liaising with critical stakeholders in the public and private sector, reviewing all Finance and Accounting transactions and ensures compliance with regulatory and statutory requirements, and safeguard GACN assets through adherence to laid down controls and reporting standards and communicate of known deviation to management.",
  },
  {
    image: image12,
    name: "DONALD ILOBA",
    role: "Chief Compliance Officer",
    link: "",
    details:
      "Donald has a rich professional background across the UK and Nigeria. His tenure at Centrica PLC, the parent company of British Gas, included managing a team responsible for the daily monitoring of gas engineers, which involved compliance with safety and regulatory standards. Upon joining GACN in 2014 as a Commercial Advisor, Donald delved into gas contract negotiations, which is intricately linked to ensuring contractual compliance. His transition to the operations department as a supervisor for the due diligence assessment team further underlines his focus on compliance, as due diligence involves thorough vetting and compliance checks on prospective gas buyers. In his most recent role in the Legal Department, where he is responsible for Corporate Compliance and Business Ethics, Donald continues to emphasize and lead in ensuring the organization's adherence to legal and ethical standards, making him a valuable asset in managing compliance across his career journey.",
  },
];

export default function Team() {
  return (
    <section className="py-20">
      <div className="container">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[35px]">
          {team.map((item) => (
            <Card key={item.name} item={item} />
          ))}
          <BiographyModal />
        </div>
      </div>
    </section>
  );
}

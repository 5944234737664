import React, { useState } from "react";
import Icons from "../../assets/icons";
import Button from "../../common/button";
import { NavLink, useLocation } from "react-router-dom";

const MenuItem = ({ onClick, link, text }) => {
  return (
    <li onClick={onClick}>
      <NavLink
        to={link}
        className={({ isActive }) =>
          `block text-sm uppercase font-semibold !leading-[16.8px] tracking-[1.68px] text-grey-900 whitespace-nowrap ${
            isActive ? "text-primary" : ""
          }`
        }
      >
        {text}
      </NavLink>
    </li>
  );
};

const MenuDropDownItem = ({
  text,
  children,
  isDropDownOpen,
  toggleDropDown,
  active,
}) => {
  return (
    <li
      onClick={toggleDropDown}
      className="relative link-with-dropDown w-full justify-between flex items-center gap-2"
    >
      <span
        className={`cursor-pointer text-sm uppercase font-semibold !leading-[16.8px] tracking-[1.68px] text-grey-900 whitespace-nowrap ${
          active ? "text-primary" : "text-grey-90"
        } `}
      >
        {text}
      </span>
      <span
        className={`-rotate-90 lg:rotate-0  ${
          active ? "text-primary" : "text-grey-90"
        } `}
      >
        {Icons.ic_down}
      </span>
      {isDropDownOpen && (
        <DropDown toggleDropDown={toggleDropDown}>{children}</DropDown>
      )}
    </li>
  );
};

const DropDown = ({ children, toggleDropDown }) => {
  return (
    <div className="z-90 w-full bg-white fixed top-0 left-0 drop-down px-4 pt-10">
      <div className="container !px-0">
        <div
          onClick={toggleDropDown}
          className="cursor-pointer flex gap-4 items-center pb-6 px-4"
        >
          <img src={Icons.ic_back_nav} alt="" />
          <p className="uppercase text-primary text-sm font-semibold leading-[16.8px] tracking-[12%]">
            BACK
          </p>
        </div>
        {children}
      </div>
    </div>
  );
};

const DropDownItem = ({ title, desc, link }) => {
  return (
    <NavLink
      to={link}
      className={({ isActive }) =>
        `block text-sm !leading-[20.3px] border-b last:border-none border-grey-10 py-4 px-4 ${
          isActive ? "bg-grey-10" : ""
        }`
      }
    >
      <p className="text-grey-900 text-sm font-semibold">{title}</p>
      <p className="text-grey-500">{desc}</p>
    </NavLink>
  );
};
const MobileNavbar = ({
  toggleContactModal,
  isContactModalOpen,
  toggleShowForm,
}) => {
  const { pathname } = useLocation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCompanyDropDownOpen, setCompanyDropDownOpen] = useState(false);
  const [isServicesDropDownOpen, setServicesDropDownOpen] = useState(false);
  const [isNewsDropDownOpen, setNewsDropDownOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const toggleDropDown = (setState, state) => {
    setState(!state);
  };

  return (
    <>
      {!isMenuOpen ? (
        <span className="mobile-nav cursor-pointer block" onClick={toggleMenu}>
          {Icons.ic_menu}
        </span>
      ) : (
        <span
          className="mobile-nav cursor-pointer block self-end w-10 h-10 bg-primary border border-solid border-primary-75 rounded-full backdrop-blur-[8px] flex items-center justify-center text-white"
          onClick={toggleMenu}
        >
          {Icons.ic_close}
        </span>
      )}
      <nav
        className={`mobile-nav fixed top-[76px] left-0 flex flex-col bg-white px-4 pt-10 pb-20 z-30 transition-all ease-in-out duration-300 w-full justify-between items-center gap-[72px] ${
          isMenuOpen
            ? "visible max-h-fit translate-y-0"
            : "invisible max-h-0 translate-y-[-100%]"
        } ${isContactModalOpen ? "block" : ""}`}
      >
        <div className="container">
          <ul
            className={`hidden flex-col justify-between gap-10 w-full ${
              isMenuOpen ? "!flex " : ""
            }`}
          >
            <MenuDropDownItem
              text={"Company"}
              toggleDropDown={() =>
                toggleDropDown(setCompanyDropDownOpen, isCompanyDropDownOpen)
              }
              isDropDownOpen={isCompanyDropDownOpen}
              setDropDownOpen={setCompanyDropDownOpen}
              active={
                pathname === "/about" ||
                pathname === "/management" ||
                pathname === "/code-of-conduct"
                  ? true
                  : false
              }
            >
              <DropDownItem
                title="About Us"
                desc="How we started"
                link="/about"
              />
              <DropDownItem
                title="Our Management Team"
                desc="Get to meet the team"
                link="/management"
              />
              <DropDownItem
                title="Code of Conduct"
                desc="Our business ethics policy"
                link="/code-of-conduct"
              />
            </MenuDropDownItem>
            <MenuDropDownItem
              text={"Services"}
              toggleDropDown={() =>
                toggleDropDown(setServicesDropDownOpen, isServicesDropDownOpen)
              }
              isDropDownOpen={isServicesDropDownOpen}
              setDropDownOpen={setServicesDropDownOpen}
              active={
                pathname === "/services" ||
                pathname === "/initiatives" ||
                pathname === "/energy-transition" ||
                pathname === "/partnerships"
                  ? true
                  : false
              }
            >
              <DropDownItem
                title="What we offer"
                desc="Enhancing gas sector growth"
                link="/services"
              />
              <DropDownItem
                title="GACN Initiatives"
                desc="Driving innovation"
                link="/initiatives"
              />
              <DropDownItem
                title="GACN Energy Transition"
                desc="Our energy transition initiative"
                link="/energy-transition"
              />
              <DropDownItem
                title="GACN Partnerships"
                desc="Forging progress"
                link="/partnerships"
              />
              <DropDownItem
                title="Whistleblower Policy"
                desc="Report concerns anonymously"
                link="/whistleblower-policy"
              />
            </MenuDropDownItem>

            <MenuItem link="/purchase-process" text={"Gas Purchase process"} />

            <MenuDropDownItem
              text={"News & insight"}
              toggleDropDown={() =>
                toggleDropDown(setNewsDropDownOpen, isNewsDropDownOpen)
              }
              isDropDownOpen={isNewsDropDownOpen}
              setDropDownOpen={setNewsDropDownOpen}
              active={
                pathname === "/news" ||
                pathname === "/publications" ||
                pathname === "/gallery"
                  ? // || pathname === "/branding"
                    true
                  : false
              }
            >
              <DropDownItem
                title="News Update"
                desc="Recent news in the industry"
                link="/news"
              />
              <DropDownItem
                title="Publications"
                desc="Helpful tips, resources"
                link="/publications"
              />
              <DropDownItem
                title="Photo gallery"
                desc="Explore our gallery"
                link="/gallery"
              />
              {/* <DropDownItem
                title="Media & Branding"
                desc="Our videos and brand guidelines"
                link="/branding"
              /> */}
            </MenuDropDownItem>
          </ul>
        </div>
        <div
          className={`container ${isMenuOpen ? "block w-full" : "hidden"}`}
          onClick={() => {
            setMenuOpen(false);
            toggleContactModal(true);
          }}
        >
          <Button fullWidth={true}>Get started</Button>
        </div>
      </nav>
    </>
  );
};

export default MobileNavbar;

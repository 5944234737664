// new query
export const newsQuery = `*[_type == "news"] {
   _id,
   title,
   smallDescription,
   slug,
   publishedDate,
   newsImage {alt, "image": asset->url},
   "categories": categories[]->{name},
   "tags": tags[]->name
   } | order(publishedDate desc)
`;

export const recentNewsQuery = `*[_type == "news"] {
   _id,
   title,
   smallDescription,
   slug,
   newsImage {alt, "image": asset->url},
   "tags": tags[]->name,
   publishedDate
   } | order(publishedDate desc) [0..4] 
`;

export const newsFilteredQuery = `*[_type == "news" && $category in categories[]->{name}.name] {
      _id,
      title,
      smallDescription,
      slug,
      publishedDate,
      newsImage {alt, "image": asset->url},
      "categories": categories[]->{name},
      "tags": tags[]->name
} | order(publishedDate desc)`;

export const newsTagQuery = `*[_type == "news" && $tag in tags[]->{name}.name] {
   _id,
   title,
   smallDescription,
   slug,
   publishedDate,
   newsImage {alt, "image": asset->url},
   "categories": categories[]->{name},
   "tags": tags[]->name
} | order(publishedDate desc)`;

export const newsSearchQuery = (
  searchText
) => `*[_type == "news" && title match "${searchText}*"] {
      _id,
      title,
      smallDescription,
      slug,
      publishedDate,
      newsImage { alt, "image": asset->url },
      "categories": categories[]->{name},
      "tags": tags[]->name
    }`;

export const newsItemQuery = `*[_type == "news" && slug.current == $slug][0] {
   _id,
   title,
   smallDescription,
   slug,
   content,
   publishedDate,
   newsImage {alt, "image": asset->url},
   "tags": tags[]->name,
   author,
}`;

// publications query
export const publicationQuery = `*[_type == "publication"] {
   _id,
   title,
   smallDescription,
   slug,
   publishedDate,
   publicationImage {alt, "image": asset->url},
   } | order(publishedDate desc)
`;

export const recentPublicationQuery = `*[_type == "publication"] {
   _id,
   title,
   smallDescription,
   slug,
   publishedDate,
   publicationImage {alt, "image": asset->url},
   } | order(publishedDate desc) [0..2]
`;

export const publicationSearchQuery = (
  searchPublicationText
) => `*[_type == "publication" && title match "${searchPublicationText}*"] {
      _id,
      title,
      smallDescription,
      slug,
      publishedDate,
      publicationImage { alt, "image": asset->url },
    }`;

export const publicationItemQuery = `*[_type == "publication" && slug.current == $slug][0] {
   _id,
   title,
   smallDescription,
   slug,
   content,
   publishedDate,
   publicationImage {alt, "image": asset->url},
   author
}`;

// photo gallery query
export const galleryQuery = `*[_type == "gallery"] {
   _id,
   title,
   slug,
   coverImage {"image": asset->url},
   publishedDate
   } | order(publishedDate desc)
`;
export const galleryItemQuery = `*[_type == "gallery" && slug.current == $slug][0] {
  _id,
   title,
   slug,
   publishedDate,
   coverImage {"image": asset->url},
   mainImage {"image": asset->url},
   images []{"image": asset->url}
}`;

import React from "react";
import Icons from "../../../assets/icons";

const priorities = [
  'Developing a post PIA "Willing Buyers - Willing Sellers" business sustainability strategy for GACN',
  "Effective implementation of the post PIA restructuring of GACN",
  "Manage the transition from 100% AOC funding arrangement to a self financing- Financial autonomy arrangement based on fees for GACN's services",
  "Maximize GACN's position as the preeminent aggregator to ensure it's preeminence in Gas commercialization landscape as a competitive advantage over any newly registered aggregator",
  "Support the Authority, MPR, and gas sector in securing clarity on the post PIA gas pricing for the respective strategic sectors",
  "Drive climate change and energy transition (Gas Flare Commercialization, Clean Energy/Carbon Swap framework, Gas-to-Chemical initiative",
  "Promotion of Gas sector investment - strategic engagement with international investment group, financing community, potential investors to expand gas investment in-country",
  "Decade of gas implementation support",
];

export default function BusinessPriorities() {
  return (
    <section id="news" className="bg-white py-20 services relative">
      <div className="container space-y-[76px]">
        <h3 className="z-10 mb-10 md:mb-16 py-3 max-w-[508px] mx-auto text-black text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span> OUR BUSINESS PRIORITIES
        </h3>
        <div className="bg-grey-50 border border-grey-200 px-4 py-12 md:p-12 space-y-8 max-w-[1008px] mx-auto">
          {priorities.map((item) => (
            <div key={item} className="flex gap-6 items-start">
              <img src={Icons.ic_points} alt="" />
              <p className="text-base md:text-lg text-grey-700 font-semibold tracking-[-2%] !leading-[32px] md:py-2">
                {item}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

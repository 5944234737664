import React from "react";

const Title = ({ text }) => (
  <span className="text-black font-semibold">{`${text} `} </span>
);

export default function Code() {
  return (
    <section className="pb-20 mt-[-34px] md:mt-[-80px]">
      <div className="container">
        <div className="bg-grey-50 border border-grey-200 px-6 pt-12 pb-[118px] md:p-12 space-y-2 max-w-[912px] mx-auto text-grey-600 text-base md:text-lg !leading-[32px]">
          <p>
            Strict compliance with GACN's Code of Conduct and Business Ethics
            Policy (CCBE) is mandatory for all staff and third parties dealing
            with GACN in order to deliver on its mandate as the Domestic
            Aggregator and secure the trust of its employees, shareholders,
            buyers, sellers, and other third parties.
          </p>
          <p>
            To this end, all staff and GACN's third parties are enjoined to
            adhere to the following:
          </p>
          <ol className="list-decimal ml-5 space-y-8">
            <li>
              <Title text="No Gratification:" /> Bribes, fees, rebates,
              kickbacks in exchange for services provided on behalf of GACN is
              totally prohibited.
            </li>
            <li>
              <Title text="Avoid Conflict of Interest: " /> All are prohibited
              from indulging in transactions or activities constituting a
              'Conflict of Interest". A Conflict of Interest entails engaging in
              any transaction or activity which conflicts with GACN's business
              interest. Where such conflicts may arise, the respective staff
              should promptly declare such to the Compliance Officer.
            </li>
            <li>
              <Title text="Strict HSSE Compliance: " />
              All shall abide by all health, safety, security, and environmental
              regulations in the conduct of GACN's business and performance of
              any tasks. For further details, please refer to the GACN HSSE
              Policy.
            </li>
            <li>
              <Title text="Confidentiality: " />
              All staff shall maintain confidentiality of all GACN related
              information and documents including abiding by the confidentiality
              obligations in all GACN agreements.
            </li>
            <li>
              <Title text="Maintenance of Accurate Records: " />
              All shall ensure that every transaction is adequately and properly
              documented, correctly recorded, and all information is properly
              maintained and secured.
            </li>
            <li>
              <Title text="No Harassment: " />
              All must maintain a work environment where everyone can perform
              their roles without any fear of harassment (e.g. bullying, use of
              offensive language, physical assault, verbal abuse, sexual
              advances and harassment, etc.).
            </li>
            <li>
              <Title text="No Alcohol or Substance Abuse: " />
              No one shall indulge in the consumption of alcohol, illicit or
              nonprescription drugs or any other prohibited stimulants or
              substances while carrying out any official activities within any
              GACN facility.
            </li>
            <li>
              <Title text="Efficient Use of Company Assets: " />
              All shall secure assets in their possession against waste, damage,
              loss, misappropriation and misuse, and the assets shall be solely
              deployed for GACN business, only.
            </li>
            <li>
              <Title text="Adherence to Proper Communication Channel: " />
              Clearance from the MD should be secured for all communications
              with third parties or the general public.
            </li>
            <li>
              <Title text="Compliance with Applicable Law: " />
              All are required to comply with all applicable laws and internal
              policies.
            </li>
            <li>
              <Title text="Non-Participation in Partisan Politics:" />
              All staff shall abstain from political activities or acts that
              could be deemed partisan. Staff are however encouraged to
              participate in government and civic processes in their personal
              capacity.
            </li>
            <li>
              <Title text="Respect for the Rights of Individuals:" /> All must
              respect the rights of individuals to privacy. Staff shall protect
              personal and classified information about individuals in their
              care from misuse.
            </li>
            <li>
              <Title text="It's Obligatory to report any CCBE Violations:" />
              Anyone aware of any violation of the GACN CCBE or the foregoing
              shall immediately report such violation to the Compliance Officer
              and the Managing Director. Please note that all reports will be
              treated with utmost confidentiality and subsequently investigated.
              However, if such submissions are found to be false or malicious,
              appropriate sanctions will be applied.
            </li>
          </ol>
          <div className="text-black space-y-2 !mt-16">
            <p>Chijioke Uzoho</p>
            <p>Managing Director</p>
            <p>February, 2024</p>
          </div>
        </div>
      </div>
    </section>
  );
}

import { useState } from "react";

const useTab = (items) => {
  const tabs = ["All", "Articles", "Events", "Conferences", "News Clips"];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return {tabs, activeTab, handleTabClick };
};

export default useTab;

import React from "react";
import GeneralAccordion from "../../../common/accordion/generalAccordion";

const accordionData = [
  {
    question: "Facilitation of Gas Flare Commercialization",
    answer:
      "Converting flared natural gas into valuable products or energy sources, mitigating environmental impact through collaboration between energy companies in creating a regulatory environment that supports the efficient utilization of this resource",
  },
  {
    question: "Carbon Neutrality Profile",
    answer:
      "Providing carbon neutrality profiling services, conducting comprehensive emissions assessments across various scopes. We guide businesses through offsetting these emissions within the voluntary market, culminating in the acquisition of a certificate affirming their carbon-neutral status for the specified offsetting year.",
  },
  {
    question: "Carbon Trading Framework",
    answer: (
      <div className="space-y-4">
        <p>
          Facilitating emissions trading within the Voluntary market, as well as
          to streamline the exchange of emissions allowances, providing a robust
          platform for businesses and organizations to effectively manage and
          offset their carbon footprints.
        </p>
        <p>
          <span className="font-medium">
            Carbon Trading:
            <br /> Definition:
          </span>{" "}
          Carbon trading, also known as emissions trading, involves the buying
          and selling of permits or credits that allow companies to emit a
          certain amount of carbon dioxide or other greenhouse gases. <br />
          <span className="font-medium">Goal:</span> The goal is to create a
          market-based incentive for companies to reduce emissions. By setting a
          cap on emissions and allowing trading of permits, it aims to achieve
          emission reductions in the most cost-effective manner possible
        </p>
        <p>
          <span className="font-medium">
            Carbon Swap:
            <br /> Definition:
          </span>{" "}
          Carbon swaps are financial contracts that allow two parties to
          exchange future carbon emissions or credits at a predetermined price.
          <br /> <span className="font-medium">Goal:</span> Carbon swaps are
          often used by companies to manage their exposure to the price
          volatility of carbon credits or to hedge against regulatory risks
          associated with carbon emissions.
        </p>
      </div>
    ),
  },
];

export default function EnergyTransitionAccordion() {
  return (
    <section className="py-20">
      <div className="container">
        <h3 className="z-10 mb-10 md:mb-16 py-3 max-w-[656px] mx-auto text-black text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span> OUR ENEGRY TRANSITION
          INITIATIVES
        </h3>
        <GeneralAccordion accordionData={accordionData} />
      </div>
    </section>
  );
}

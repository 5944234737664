/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Card from "./card";

import usePagination from "../../hooks/usePagination";
import Pagination from "../../common/pagination";
import useFetchSanityData from "../../hooks/useFetchSanityData";
import InnerBanner from "./innerBanner";
import Icons from "../../assets/icons";

export default function Publications() {
  const {
    publications,
    searchPublicationText,
    loadingPublications,
    fetchPublications,
    handlePublicationSearch,
  } = useFetchSanityData();

  const { currentItems, pageCount, handlePageClick } = usePagination(
    publications,
    6,
    searchPublicationText
  );

  useEffect(() => {
    fetchPublications();
  }, []);

  return (
    <>
      <InnerBanner
        searchText={searchPublicationText}
        handleSearch={handlePublicationSearch}
        fetchPublication={fetchPublications}
      />
      <section className="pb-20 mt-[-34px] md:mt-[-80px]">
        {loadingPublications ? (
          <div className="pt-[128px] pb-[48px] flex justify-center items-center bg-white text-center">
            {Icons.ic_loading}
          </div>
        ) : publications?.length > 0 ? (
          <div className="container">
            <div className="bg-grey-50 border border-grey-200 px-4 py-0 md:p-12 space-y-2 max-w-[1216px] mx-auto text-grey-600 text-base md:text-lg !leading-[32px]">
              <div className="tab-content">
                <Pagination
                  currentItems={currentItems}
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                >
                  {currentItems &&
                    currentItems?.map((item) => (
                      <Card key={item._id} item={item} />
                    ))}
                </Pagination>
              </div>
            </div>
          </div>
        ) : (
          <div className="pt-[128px] pb-[48px] flex justify-center items-center bg-white text-center">
            {searchPublicationText && searchPublicationText.trim() !== "" ? (
              <div className="space-y-4 flex flex-col justify-center items-center">
                <img src={Icons.ic_empty_state} alt="" />
                <p className="text-black text-lg font-semibold !leading-[26.1px]">
                  No search results
                </p>
              </div>
            ) : (
              <div className="space-y-4 flex flex-col justify-center items-center">
                <img src={Icons.ic_empty_state} alt="" />
                <div className="space-y-[10px]">
                  <p className="text-black text-lg font-semibold !leading-[26.1px]">
                    No Publications Available
                  </p>
                  <p className="text-grey-900 !leading-[23.2px]">
                    Check back soon or explore other categories
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
}

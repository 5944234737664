import { createContext, useReducer } from "react";

const initialState = {
  isBiographyModalOpen: false,
  biography: {},
};

const HANDLERS = {
  TOGGLE: "TOGGLE",
  SET_BIOGRAPHY: "SET_BIOGRAPHY",
};

const BiographyModal = createContext({
  isBiographyModalOpen: false,
  dispatch: () => null,
});

const reducer = (state, action) => {
  const { isBiographyModalOpen, biography } = action.payload;

  switch (action.type) {
    case HANDLERS.TOGGLE:
      return {
        ...state,
        isBiographyModalOpen,
      };
    case HANDLERS.SET_BIOGRAPHY:
      return {
        ...state,
        biography,
      };

    default:
      throw new Error("Something went wrong");
  }
};

const BiographyModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BiographyModal.Provider
      value={{
        isBiographyModalOpen: state.isBiographyModalOpen,
        biography: state.biography,
        dispatch,
      }}
    >
      {children}
    </BiographyModal.Provider>
  );
};

export { BiographyModal, BiographyModalProvider };

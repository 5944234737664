/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from "react";
import Card from "./card";

import usePagination from "../../hooks/usePagination";
import Pagination from "../../common/pagination";
import InnerBanner from "./innerBanner";
import Icons from "../../assets/icons";
import { NewsModal } from "../../context/newsContext";

export default function News({ tabs, activeTab, handleTabClick }) {
  const {
    news,
    newsTag,
    loadingNews,
    searchNewsText,
    fetchNews,
    handleNewsSearch,
  } = useContext(NewsModal);

  const { currentItems, pageCount, handlePageClick } = usePagination(
    news,
    6,
    searchNewsText,
    activeTab
  );

  useEffect(() => {
    fetchNews(activeTab);
  }, [activeTab, newsTag]);

  return (
    <>
      <InnerBanner
        tabs={tabs}
        activeTab={activeTab}
        handleTabClick={handleTabClick}
        searchText={searchNewsText}
        handleSearch={handleNewsSearch}
        fetchNews={fetchNews}
        newsTag={newsTag}
      />
      <section className="pb-20 mt-[-34px] md:mt-[-80px]">
        {loadingNews ? (
          <div className="pt-[128px] pb-[48px] flex justify-center items-center bg-white text-center">
            {Icons.ic_loading}
          </div>
        ) : news.length > 0 ? (
          <div className="container">
            <div className="bg-grey-50 border border-grey-200 px-4 py-0 md:p-12 space-y-2 max-w-[1216px] mx-auto text-grey-600 text-base md:text-lg !leading-[32px]">
              {newsTag || searchNewsText ? (
                <Pagination
                  pageCount={pageCount}
                  handlePageClick={handlePageClick}
                >
                  {currentItems &&
                    currentItems?.map((item) => (
                      <Card key={item._id} item={item} />
                    ))}
                </Pagination>
              ) : (
                <div className="tab-content">
                  {activeTab === "All" && (
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    >
                      {currentItems &&
                        currentItems?.map((item) => (
                          <Card key={item._id} item={item} />
                        ))}
                    </Pagination>
                  )}
                  {activeTab === "Articles" && (
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    >
                      {currentItems &&
                        currentItems?.map((item) => (
                          <Card key={item._id} item={item} />
                        ))}
                    </Pagination>
                  )}
                  {activeTab === "Events" && (
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    >
                      {currentItems &&
                        currentItems?.map((item) => (
                          <Card key={item._id} item={item} />
                        ))}
                    </Pagination>
                  )}
                  {activeTab === "Conferences" && (
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    >
                      {currentItems &&
                        currentItems?.map((item) => (
                          <Card key={item._id} item={item} />
                        ))}
                    </Pagination>
                  )}
                  {activeTab === "News Clips" && (
                    <Pagination
                      pageCount={pageCount}
                      handlePageClick={handlePageClick}
                    >
                      {currentItems &&
                        currentItems?.map((item) => (
                          <Card key={item._id} item={item} />
                        ))}
                    </Pagination>
                  )}
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="pt-[128px] pb-[48px] flex justify-center items-center bg-white text-center">
            {searchNewsText && searchNewsText.trim() !== "" ? (
              <div className="space-y-4 flex flex-col justify-center items-center">
                <img src={Icons.ic_empty_state} alt="" />
                <p className="text-black text-lg font-semibold !leading-[26.1px]">
                  No search results
                </p>
              </div>
            ) : (
              <div className="space-y-4 flex flex-col justify-center items-center">
                <img src={Icons.ic_empty_state} alt="" />
                <div className="space-y-[10px]">
                  <p className="text-black text-lg font-semibold !leading-[26.1px]">
                    {activeTab === "All"
                      ? "No News or Updates Found"
                      : `No News or Updates Found in ${activeTab} Category`}
                  </p>
                  <p className="text-grey-900 !leading-[23.2px]">
                    Stay tuned! New updates will be posted soon
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </section>
    </>
  );
}

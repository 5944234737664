/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Card from "./card";

import useFetchSanityData from "../../../hooks/useFetchSanityData";
import InnerBanner from "../../../common/innerBanner";
import { useParams } from "react-router-dom";
import Icons from "../../../assets/icons";

export default function GalleryDetails() {
  const { slug } = useParams();

  const { galleryItem, fetchGalleryItem } = useFetchSanityData();

  useEffect(() => {
    fetchGalleryItem(slug);
  }, [slug]);

  if (!galleryItem) {
    return (
      <div className="py-[128px] flex justify-center items-center">
        {Icons.ic_loading}
      </div>
    );
  }

  return (
    <>
      <InnerBanner
        name="photos"
        title={galleryItem?.title}
        bannerImage={galleryItem?.coverImage?.image}
      />

      <section className="pb-20 mt-[-34px] md:mt-[-80px]">
        <div className="container">
          <div className="bg-grey-50 border border-grey-200 px-4 py-12 md:p-14 max-w-[1216px] mx-auto">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-x-6 md:gap-y-14">
              {galleryItem?.images?.map((image, index) => (
                <Card key={index} item={image} />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

import React from "react";

import Layout from "../components/layout";
import NewsDetail from "../components/news/details";

const NewsDetailsPage = () => {
  return (
    <Layout>
      <NewsDetail />
    </Layout>
  );
};

export default NewsDetailsPage;

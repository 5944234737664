import React from "react";

import Layout from "../components/layout";
import News from "../components/news";
import useTab from "../hooks/useTab";

const NewsPage = () => {
  const { tabs, activeTab, handleTabClick } = useTab();

  return (
    <Layout>
      <News tabs={tabs} activeTab={activeTab} handleTabClick={handleTabClick} />
    </Layout>
  );
};

export default NewsPage;

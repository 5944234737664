import { useContext } from "react";
import { BiographyModal } from "../context/biographyModalContext";

const useBiographyModal = () => {
  const { isBiographyModalOpen,biography, dispatch } = useContext(BiographyModal);

  const toggleBiographyModal = (value) => {
    dispatch({
      type: "TOGGLE",
      payload: {
        isBiographyModalOpen: value,
      },
    });
  };

  const setBiography = (value) => {
    dispatch({
      type: "SET_BIOGRAPHY",
      payload: {
        biography: value,
      },
    });
  };

  return {
    toggleBiographyModal,
    setBiography,
    isBiographyModalOpen,
    biography,
  };
};

export default useBiographyModal;

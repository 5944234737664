import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import ItemCard from "./itemCard.jsx";
import image1 from "../../../assets/images/services/image1.png";
import image2 from "../../../assets/images/services/image2.png";
import image3 from "../../../assets/images/services/image3.jpg";
import image4 from "../../../assets/images/services/image4.png";
import image5 from "../../../assets/images/services/image5.png";
import image6 from "../../../assets/images/services/image6.jpg";
import image7 from "../../../assets/images/services/image7.svg";
import image8 from "../../../assets/images/services/image8.png";
import Button from "../../../common/button.jsx";
import { Link } from "react-router-dom";

function Services() {
  return (
    <section id="services" className="bg-grey-900 py-20 services relative">
      <div className="container">
        <h3 className="z-10 mb-16 py-3 text-white text-[28px] font-bold tracking-[-0.56px] border-y-2 border-solid border-white md:border-grey-500 ">
          <span className="text-primary-200">/</span> OUR SERVICES
        </h3>
        <div className="">
          <Swiper
            slidesPerView={1}
            spaceBetween={24}
            breakpoints={{
              0: {
                slidesPerView: 2,
                spaceBetween: 250,
                slidesPerGroup: 2,
              },

              440: {
                slidesPerView: 2,
                spaceBetween: 110,
                slidesPerGroup: 2,
              },

              640: {
                slidesPerView: 2,
                spaceBetween: 70,
                slidesPerGroup: 2,
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 200,
                slidesPerGroup: 3,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
                slidesPerGroup: 3,
              },
              1200: {
                slidesPerView: 4,
                spaceBetween: 140,
                slidesPerGroup: 4,
              },
            }}
            loop={true}
            modules={[Navigation, Pagination, A11y]}
            navigation
            pagination={{
              clickable: true,
              type: "progressbar",
            }}
          >
            <SwiperSlide>
              <ItemCard title="Network Code Support" image={image1} />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="Price Aggregation and Escrow Management"
                image={image2}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard title="Flexible Gas Supply Framework" image={image3} />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard title="Gas Purchase Order Allocation" image={image4} />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="Gas Sector Support and Advisory services"
                image={image5}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="Gas Sourcing and Commercialization Facilitation"
                image={image6}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="Gas Flare Commercialization Program Support"
                image={image7}
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard title="Market Data Repository" image={image8} />
            </SwiperSlide>
          </Swiper>
          <div className="flex pt-16 md:pt-0 md:absolute z-50 md:left-5 md:bottom-0">
            <Link to="/services" className="block mx-auto">
              <Button>View all</Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Services;

import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import ServicesInfo from "../components/services";

const ServicesPage = () => {
  return (
    <Layout contact={true}>
      <InnerBanner
        name="small"
        title="OUR SERVICES"
        description="At GACN, innovation is key. We're leading change in Nigeria's gas sector with initiatives aimed at efficiency, market expansion, and sustainable development."
        bannerImage="bg-services-bannerMobile md:bg-services-bannerDesktop"
      />
      <ServicesInfo />
    </Layout>
  );
};

export default ServicesPage;

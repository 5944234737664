import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import WhistleblowerPolicyNavigation from "../components/whistleblowerPolicy/navigation";
import WhistleblowerPolicyTrustOurProcess from "../components/whistleblowerPolicy/trustOurProcess";
import WhistleblowerPolicyAccordion from "../components/whistleblowerPolicy/accrodion";
import WhistleblowerPolicySendReport from "../components/whistleblowerPolicy/sendReport";

const WhistleblowerPolicyPage = () => {
  return (
    <Layout contact={false}>
      <InnerBanner
        name="big"
        title="GACN's Commitment to Transparency and Accountability"
        description="Encouraging a culture of transparency and integrity at GACN. Your report matters."
        bannerImage="bg-energy-banner"
        button
        buttonText="Submit an Anonymous Report"
        buttonLink="https://forms.office.com/Pages/ResponsePage.aspx?id=oZmsaR7yAUiAMZp_B1xPJEIGMfAHvgdDlFJ45mK26TFUODlWOEtIUU4yTkxIVkUwRllJTzczQk1HVS4u"
      />

      <WhistleblowerPolicyNavigation />
      <WhistleblowerPolicyTrustOurProcess />
      <WhistleblowerPolicyAccordion />
      <WhistleblowerPolicySendReport />
    </Layout>
  );
};

export default WhistleblowerPolicyPage;

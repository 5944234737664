import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import Process from "../components/purchaseProcess/process";
import useContactModal from "../hooks/useContactModal";

const PurchaseProcessPage = () => {
  const { toggleContactModal } = useContactModal();

  return (
    <Layout>
      <InnerBanner
        name="small"
        title="GAS PURCHASE PROCESS"
        description="Streamlining Your Access to Reliable Gas Supply"
        bannerImage="bg-purchaseProcess-bannerMobile md:bg-purchaseProcess-bannerDesktop"
      />
      <Process toggleContactModal={toggleContactModal} />
    </Layout>
  );
};

export default PurchaseProcessPage;

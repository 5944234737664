import React from "react";
import Card from "./card";
import Icons from "../../assets/icons";
import Button from "../../common/button";

const processes = [
  {
    stepNumber: "1",
    icon: Icons.ic_step1,
    title: "Submit your gas request form",
    description: " Steps on how we intend to serve you",
  },
  {
    stepNumber: "2",
    icon: Icons.ic_step2,
    title: "We process your Gas request form",
    description: "Steps on how we intend to serve you",
  },
  {
    stepNumber: "3",
    icon: Icons.ic_step3,
    title: "Buyer completes due diligence questionnaire",
    description: "Steps on how we intend to serve you",
  },
  {
    stepNumber: "4",
    icon: Icons.ic_step4,
    title: "We conduct a buyer due diligence exercise",
    description: "Steps on how we intend to serve you",
  },
  {
    stepNumber: "5",
    icon: Icons.ic_step5,
    title: "Qualified buyer is accepted into our demand gas pool",
    description: "Steps on how we intend to serve you",
  },
  {
    stepNumber: "6",
    icon: Icons.ic_step6,
    title: "Buyer is issued GPO and Template GSAA",
    description: "Steps on how we intend to serve you",
  },
  {
    stepNumber: "7",
    icon: Icons.ic_step7,
    title: "GSAA is negotiated & executed between Us, Buyer & Seller",
    description: "Steps on how we intend to serve you",
  },
];

export default function Process({ toggleContactModal }) {
  return (
    <section className="pt-10 pb-20 md:py-20">
      <div className="container space-y-16">
        <div className="space-y-10">
          {processes.map((process) => (
            <Card
              key={process.stepNumber}
              stepNumber={process.stepNumber}
              icon={process.icon}
              title={process.title}
              description={process.description}
            />
          ))}
        </div>

        <div className="flex">
          {/* <a
            href="https://gacn-dashboard-git-main-gacn.vercel.app"
            target="_blank"
            rel="noopener noreferrer"
          > */}
          <Button
            onClick={() => toggleContactModal(true)}
            className="mx-auto sm:mx-0"
          >
            Get started
          </Button>
          {/* </a> */}
        </div>
      </div>
    </section>
  );
}

import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";

import "swiper/css";

import nnpc from "../../assets/images/partners/nnpc.svg";
import chevron from "../../assets/images/partners/chevron.svg";
import shell from "../../assets/images/partners/shell.svg";
import exxon from "../../assets/images/partners/exxon.svg";
import total from "../../assets/images/partners/total.svg";
import seplat from "../../assets/images/partners/seplat.svg";
import AGPC from "../../assets/images/partners/AGPC.png";
import dangote from "../../assets/images/partners/dangote.svg";
import ndwestern from "../../assets/images/partners/NDWestern.svg";
// import axxela from "../../assets/images/partners/axxela.png";

const Members = () => {
  return (
    <section className="container members">
      <div className="flex items-center gap-3 md:gap-10 py-6">
        <p className="text-grey-400 text-sm md:text-lg uppercase font-semibold">
          Our <br /> MEMBERS:
        </p>
        <Swiper
          slidesPerView={3}
          spaceBetween={12}
          breakpoints={{
            0: {
              slidesPerView: 3,
              spaceBetween: 12,
              // slidesPerGroup: 3,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 24,
              // slidesPerGroup: 4,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 60,
              // slidesPerGroup: 5,
            },
            1200: {
              slidesPerView: 6,
              spaceBetween: 72,
              // slidesPerGroup: 6,
            },
          }}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay]}
        >
          <SwiperSlide>
            <img src={nnpc} width={182} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={chevron} width={112} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={shell} width={112} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={exxon} width={112} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={total} width={112} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={AGPC} width={112} alt="" />
          </SwiperSlide>

          <SwiperSlide>
            <img src={dangote} width={112} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img src={ndwestern} width={112} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img
              src={seplat}
              className="w-[36px] md:w-[71px] mx-auto"
              width={71}
              alt=""
            />
          </SwiperSlide>
          {/* <SwiperSlide>
            <img src={axxela} width={112} alt="" />
          </SwiperSlide> */}
        </Swiper>
      </div>
      {/* </div> */}
    </section>
  );
};

export default Members;

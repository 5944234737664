import { Suspense, useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./config";
import { ContactModalProvider } from "../context/contactModalContext";
import { BiographyModalProvider } from "../context/biographyModalContext";
import { NewsModalProvider } from "../context/newsContext";

const Router = () => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = "manual";
    }

    return () => {
      if ("scrollRestoration" in window.history) {
        window.history.scrollRestoration = "auto";
      }
    };
  }, []);

  return (
    <Suspense fallback={null}>
      <ContactModalProvider>
        <BiographyModalProvider>
          <NewsModalProvider>
            <RouterProvider router={routes}></RouterProvider>
          </NewsModalProvider>
        </BiographyModalProvider>
      </ContactModalProvider>
    </Suspense>
  );
};

export default Router;

import Button from "./button";

// compile all the innerbanner here
export default function InnerBanner({
  name,
  title,
  description,
  bannerImage,
  button,
  buttonLink,
  buttonText,
}) {
  return name === "photos" ? (
    <section
      style={{
        background: `url(${bannerImage}`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
      className={`py-[80px] md:pt-[172px] md:pb-[80px] flex items-center relative`}
    >
      <div className="bg-primary-900 absolute w-full h-full top-0 left-0 opacity-25"></div>
      <div className="container">
        <h3 className="py-3 pb-[140px] md:pb-[140px] max-w-[508px] mx-auto uppercase text-white text-xl md:text-[28px] text-center font-bold !leading-[33.6px] tracking-[-0.56px]">
          {title}
        </h3>
      </div>
    </section>
  ) : name === "big" ? (
    <section
      className={`${bannerImage} bg-cover bg-no-repeat bg-center pt-[112px] md:pt-[219px] pb-[185px] md:pb-[140px] flex items-center relative  md:h-auto`}
    >
      <div className="bg-primary-800 absolute w-full h-full top-0 left-0 opacity-25"></div>
      <div className="container">
        <p className="mb-10 md:mb-12 pb-4 text-white text-xl font-semibold tracking-[-0.4px] border-b-2 border-solid border-grey-200">
          {title}
        </p>
        <h1 className="text-white text-[28px] md:text-[56px] font-bold tracking-[-0.56px] md:tracking-[-2.24px] pb-10 md:pb-16 lg:w-[70%]">
          {description}
        </h1>
        {button && (
          <a href={buttonLink} target="_blank" rel="noreferrer">
            <Button className="!w-fit">{buttonText}</Button>
          </a>
        )}
      </div>
    </section>
  ) : name === "small" ? (
    <section
      className={`${bannerImage} bg-cover bg-no-repeat bg-center py-[80px] md:pt-[112px] md:pb-[64px] flex items-center relative`}
    >
      <div className="bg-primary-800 absolute w-full h-full top-0 left-0 opacity-25"></div>
      <div className="container space-y-12">
        <h3 className="py-3 max-w-[508px] mx-auto text-white text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span> {title}
        </h3>
        <p className="capitalize text-white text-center text-lg tracking-[-2%] max-w-[592px] mx-auto">
          {description}
        </p>
      </div>
    </section>
  ) : (
    <section className="bg-primary-900 pt-[80px] md:pt-[112px] pb-[114px] md:pb-[160px] flex items-center">
      <div className="container space-y-12">
        <h3 className="py-3 max-w-[508px] mx-auto text-white text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-primary-600">
          <span className="text-primary-200">/</span> {title}
        </h3>
        <p className="capitalize text-white text-center text-lg tracking-[-2%] max-w-[592px] mx-auto">
          {description}
        </p>
      </div>
    </section>
  );
}

import React from "react";

const termsData = [
  {
    title: "Introduction",
    description:
      "Welcome to the Gas Aggregator Company Nigeria Ltd /GTE(GACN) website. By accessing our site, you agree to comply with and be bound by the following terms and conditions of use.",
  },
  {
    title: "Use of the Site",
    description:
      "The content of the pages of this website is for your general information and use only. It is subject to change without notice.",
  },
  {
    title: "Copyright and Trademarks",
    description:
      "The trademarks, logos, and service marks displayed on the site are the property of GACN or other third parties. You are not permitted to use these marks without the prior written consent of GACN or such third party which may own the mark.",
  },
  {
    title: "User Conduct",
    description:
      "The trademarks, logos, and service marks displayed on the site are the property of GACN or other third parties. You are not permitted to use these marks without the prior written consent of GACN or such third party which may own the mark.",
  },
  {
    title: "Disclaimer",
    description:
      "The information contained in this website is for general information purposes only. While we endeavor to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability concerning the website or the information, products, services, or related graphics contained on the website for any purpose.",
  },
  {
    title: "Limitation of Liability",
    description:
      "GACN will not be liable for any damages of any kind arising from the use of this site, including, but not limited to direct, indirect, incidental, punitive, and consequential damages.",
  },
  {
    title: "Governing Law",
    description:
      "These terms and conditions are governed by and construed by the laws of Nigeria and any disputes will be resolved exclusively in the Nigerian courts.",
  },
  {
    title: "Changes to Terms",
    description:
      "GACN reserves the right to modify these terms and conditions at any time. Your continued use of the site after any such changes indicates your acceptance of the new terms.",
  },
  {
    title: "Contact Information",
    description:
      "For any questions or comments regarding these terms, please contact us directly.",
  },
];

const Title = ({ text }) => (
  <span className="text-black font-medium">{`${text} `} </span>
);

export default function Terms() {
  return (
    <section className="pb-20 mt-[-34px] md:mt-[-80px]">
      <div className="container">
        <div className="bg-grey-50 border border-grey-200 px-6 py-12 md:p-12 space-y-2 max-w-[912px] mx-auto text-grey-600 text-base md:text-lg !leading-[32px]">
          {termsData.map((terms, index) => (
            <div
              key={terms.title}
              className="py-8 last:pb-0 space-y-6 border-b last:border-none border-grey-300"
            >
              <h6 className="text-black text-lg md:text-xl font-semibold">
                {terms.title}{" "}
              </h6>

              {index === termsData.length - 1 ? (
                <div className="space-y-3">
                  <p className=" ">{terms.description}</p>
                  <p>
                    <Title text="Address:" />
                    1B Malcom Fraser Street Asokoro, Abuja, Nigeria
                  </p>
                  <p>
                    <Title text="Email:" />
                    <a href="mainto:info@gacn.com" className="text-primary">
                      info@gacn.com
                    </a>
                  </p>
                  <p>
                    <Title text="Call:" />
                    <a href="tel:02094605830" className="text-primary">
                      02094605830
                    </a>
                  </p>
                </div>
              ) : (
                <p className=" ">{terms.description}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

const Button = ({
  children,
  onClick,
  fullWidth,
  height,
  className,
  disabled,
}) => (
  <button
    className={`text-sm uppercase font-semibold tracking-[1.68px] rounded-lg py-2 px-4 pointer 
    ${fullWidth ? "w-full" : "w-[220px]"} 
    ${height ? height : "h-[56px]"}
    ${className} 
    ${disabled ? "bg-primary-800 text-grey-500" : "bg-primary text-white"}`}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

export default Button;

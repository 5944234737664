import React from "react";
import { Link } from "react-router-dom";
import Icons from "../../assets/icons";
import { format } from "date-fns";

export default function Card({ item }) {
  const { publishedDate, title, smallDescription, slug, newsImage } = item;
  return (
    <div className="flex justify-between flex-col-reverse md:flex-row gap-8 py-10 border-b border-grey-300">
      <div>
        <span className="block text-grey-400 font-medium !leading-[23.2px] mb-4">
          {publishedDate?format(new Date(publishedDate), "LLL. dd, yyyy"):''}
        </span>
        <h2 className="text-black text-lg md:text-2xl font-bold !leading-[32px] mb-6">
          {title}
        </h2>
        <p className="text-grey-500 text-base md:text-lg !leading-[24px] mb-8 h-[48px] line-clamp-2 text-ellipsis">
          {smallDescription}
        </p>
        <Link
          to={`/news/${slug?.current}`}
          className="block bg-white p-2 border-2 border-solid border-grey-10 rounded-lg flex items-center gap-2 w-[218px]"
        >
          <span className="text-primary text-sm uppercase font-semibold tracking-[1.68px]">
            Continue reading
          </span>
          {Icons.ic_new_tab}
        </Link>
      </div>
      <div className="w-full md:min-w-[384px] md:max-w-[384px]  ">
        <img
          src={newsImage?.image}
          className="h-[214px] md:w-[384px] object-cover w-full"
          alt=""
        />
      </div>
    </div>
  );
}

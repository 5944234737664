import { createContext, useReducer } from "react";

const initialState = {
  isContactModalOpen: false,
};

const HANDLERS = {
  TOGGLE: "TOGGLE",
};

const ContactModal = createContext({
  isContactModalOpen: false,
  dispatch: () => null,
});

const reducer = (state, action) => {
  const { isContactModalOpen } = action.payload;

  switch (action.type) {
    case HANDLERS.TOGGLE:
      return {
        ...state,
        isContactModalOpen,
      };

    default:
      throw new Error("Something went wrong");
  }
};

const ContactModalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ContactModal.Provider
      value={{
        isContactModalOpen: state.isContactModalOpen,
        dispatch,
      }}
    >
      {children}
    </ContactModal.Provider>
  );
};

export { ContactModal, ContactModalProvider };

import React from "react";
import Button from "../../common/button";
import { Link } from "react-router-dom";
import Icons from "../../assets/icons";

export default function NotFound() {
  return (
    <section className="py-10">
      <div className="container min-h-[calc(100vh-145px)] flex flex-col justify-center items-center gap-8">
        <img src={Icons.ic_empty_state} alt="" />
        <div className="relative space-y-4 text-center max-w-[542px] mx-auto pb-8">
          <h1 className="absolute inset-0 top-[-40px] md:top-[-50px] text-[132px] md:text-[156px] text-grey-10 font-semibold z-[-1]">
            404
          </h1>
          <h6 className="text-xl md:text-2xl font-semibold leading-6 md:leading-[28.8px] tracking-[-2%]">
            We currently can’t find the page you are looking for.
          </h6>
          <p className="text-lg leading-[26.1px]">Please check back again</p>
        </div>
        <Link to="/">
          <Button>back to home page</Button>
        </Link>
      </div>
    </section>
  );
}

import React from "react";
import Button from "../../common/button";

const Contact = ({ toggleContactModal }) => {
  return (
    <section id="contact" className="flex bg-grey-900 ">
      <div className="w-full px-4 md:px-0 py-20 md:w-[52%] md:py-[109px]">
        <div className="max-w-[290px] md:max-w-[372px] mx-auto text-center md:text-left">
          <h3 className="text-primary mx-auto text-xl md:text-[28px] font-semibold tracking[-0.56px] pb-4 w-[90%] md:w-auto">
            Ready to Transform Your Gas Supply Experience?
          </h3>
          <p className="text-grey-400 text-lg pb-12">
            Join us in driving Nigeria's energy future forward
          </p>
          <Button onClick={() => toggleContactModal(true)}>
            Connect with GACN
          </Button>
        </div>
      </div>
      <div className="bg-contact-image bg-norepeat bg-cover w-[48%] hidden md:block"></div>
    </section>
  );
};

export default Contact;

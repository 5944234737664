import React from "react";
import About from "../components/home/about";
import Value from "../components/home/value";
import Hero from "../components/home/hero";
import Members from "../components/members";
import Services from "../components/home/services";
import News from "../components/home/news";
import Layout from "../components/layout";
import useContactModal from "../hooks/useContactModal";

const HomePage = () => {
  const { toggleContactModal } = useContactModal();
  return (
    <Layout contact={true}>
      <Hero />
      <Members />
      <About />
      <Value toggleContactModal={toggleContactModal} />
      <Services />
      <News />
    </Layout>
  );
};

export default HomePage;

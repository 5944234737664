import React from "react";

import Layout from "../components/layout";
import Members from "../components/members";
import PartnershipsAccordion from "../components/partnerships/accrodion";
import InnerBanner from "../common/innerBanner";

const PartnershipsPage = () => {
  return (
    <Layout contact={true}>
      <InnerBanner
        name="small"
        title="GACN PARTNERSHIPS"
        description=" Strategic partnerships are vital at GACN. We collaborate with industry leaders to innovate, stabilize supply, and grow Nigeria's gas sector."
        bannerImage="bg-partnership-bannerMobile md:bg-partnership-bannerDesktop"
      />
      <PartnershipsAccordion />
      <Members />
    </Layout>
  );
};

export default PartnershipsPage;

import React, { useEffect } from "react";
import Header from "../header";
import Footer from "../footer";
import ContactModal from "../contact/contactModal";
import useContactModal from "../../hooks/useContactModal";
import Contact from "../contact";
import { useLocation } from "react-router-dom";

export default function Layout({ children, contact, footer }) {
  const { toggleContactModal, isContactModalOpen } = useContactModal();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);



  return (
    <>
      <Header toggleContactModal={toggleContactModal} />
      <div className="mt-[76px] lg:hidden" />
      {children}

      <ContactModal
        toggleContactModal={toggleContactModal}
        isContactModalOpen={isContactModalOpen}
      />
      {contact && <Contact toggleContactModal={toggleContactModal} />}
      {!footer && <Footer toggleContactModal={toggleContactModal} />}
    </>
  );
}

import React from "react";

function ItemCard({ title, image }) {
  return (
    <div className="flex flex-col gap-4 w-[273px] sm:w-[286px] md:pb-[144px]">
      <img
        src={image}
        alt=""
        className="h-[260px] object-cover"
      />
      <h5 className="text-white text-xl font-medium md:h-[84px] md:h-auto w-[80%] md:w-3/4">
        {title}
      </h5>
    </div>
  );
}

export default ItemCard;

import React from "react";

import Layout from "../components/layout";
import Company from "../components/about/company";
import HowWeStarted from "../components/about/howWeStarted";
import WhatWeDo from "../components/about/whatWeDo";
import BusinessPriorities from "../components/about/businessPriorities";
import InnerBanner from "../common/innerBanner";
import Vision from "../components/about/vision";

const AboutPage = () => {
  return (
    <Layout contact={true}>
      <InnerBanner
        name="big"
        title="About GACN Ltd/Gte"
        description="Pioneering Nigeria’s Gas Sector for Sustainable Industrialization"
        bannerImage="bg-about-banner"
      />
      <Company />
      <HowWeStarted />
      <Vision/>
      <WhatWeDo />
      <BusinessPriorities />
    </Layout>
  );
};

export default AboutPage;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";

import "swiper/css";
import "swiper/css/navigation";

import ItemCard from "./itemCard.jsx";
// import image1 from "../../../assets/images/news/image1.png";
// import image2 from "../../../assets/images/news/image2.png";
// import image3 from "../../../assets/images/news/image3.png";
// import image4 from "../../../assets/images/news/image4.png";
// import image5 from "../../../assets/images/news/image5.png";

import layerBlurGreen from "../../../assets/images/value/layer_blur_green.svg";
import layerBlurYellow from "../../../assets/images/value/layer_blur_yellow.svg";
import Button from "../../../common/button.jsx";
import { Link } from "react-router-dom";
import Icons from "../../../assets/icons/index.js";
import useNewsData from "../../../hooks/useNewsData.js";

function News() {
  const { recentNews, loadingRecentNews, fetchRecentNews } =
    useNewsData();

  useEffect(() => {
    fetchRecentNews();
  }, []);

  return (
    <section id="news" className="bg-white py-20 services relative">
      <img src={layerBlurYellow} className="absolute top-0 left-0" alt="" />
      <img src={layerBlurGreen} className="absolute bottom-0 right-0" alt="" />
      <div className="container">
        <h3 className="z-10 mb-10 md:mb-16 py-3 max-w-[508px] mx-auto text-black text-xl md:text-[28px] text-center font-bold tracking-[-0.56px] border-y border-solid border-grey-200 md:border-grey-400">
          <span className="text-primary-200">/</span> NEWS UPDATE
        </h3>
        {loadingRecentNews ? (
          <div className="flex justify-center items-center">
            {Icons.ic_loading}
          </div>
        ) : (
          <>
            {recentNews.length > 0 ? (
              <div className="">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={24}
                  breakpoints={{
                    0: {
                      slidesPerView: 2,
                      spaceBetween: 250,
                    },

                    440: {
                      slidesPerView: 2,
                      spaceBetween: 110,
                    },

                    640: {
                      slidesPerView: 2,
                      spaceBetween: 70,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 200,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 20,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 140,
                    },
                  }}
                  loop={true}
                  modules={[Navigation, A11y]}
                  navigation
                >
                  {recentNews?.map((news) => (
                    <SwiperSlide key={news.slug?.current}>
                      <ItemCard
                        title={news.title}
                        image={news.newsImage?.image}
                        link={news.slug?.current}
                      />
                    </SwiperSlide>
                  ))}

                  {/* <SwiperSlide>
              <ItemCard
                title="NNPC TRANSITION- A NEW DAWN FOR A NEW ERA"
                image={image1}
                link="https://statehouse.gov.ng/news/president-buharis-speech-at-the-unveiling-of-nnpc-limited/"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="Nigeria and Canada: Collaborating to Decarbonize Nigeria’s Oil and Gas Sector"
                image={image2}
                link="https://newtelegraphng.com/nigeria-canada-collaborate-to-decarbonise-oil-industry/"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title=" 2nd West Africa LPG Expo 2022 & NLPGA Summit 2022 – Nigeria 2021"
                image={image3}
                link="https://www.petrolplaza.com/events/26649"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="2021 KADUNA INVESTMENT AND ECONOMIC SUMMIT"
                image={image4}
                link="https://energycapitalpower.com/nigeria-kano-signs-mou-with-gacn-and-nnpc-for-akk-gas-project/"
              />
            </SwiperSlide>
            <SwiperSlide>
              <ItemCard
                title="NIGERIAN OIL AND GAS CONFERENCE 2022"
                image={image5}
                link="https://www.financialnigeria.com/21st-nog-conference-exhibition-event-347.html"
              />
            </SwiperSlide> */}
                </Swiper>

                <div className="flex pt-16 md:pt-0 md:absolute z-50 md:left-5 md:bottom-8">
                  <Link to="/news" className="block mx-auto">
                    <Button className="mx-auto">Read more</Button>
                  </Link>
                </div>
              </div>
            ) : (
              <div className="space-y-4 flex flex-col justify-center items-center text-center">
                <img src={Icons.ic_empty_state} alt="" />
                <div className="space-y-[10px]">
                  <p className="text-black text-lg font-semibold !leading-[26.1px]">
                    No News or Updates Found
                  </p>
                  <p className="text-grey-900 !leading-[23.2px]">
                    Stay tuned! New updates will be posted soon
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </section>
  );
}

export default News;

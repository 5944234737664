import React from "react";

function Tab({ tabs, activeTab, handleTabClick }) {
  return (
    <div className="overflow-auto">
      <div className="flex justify-center gap-3 mt-4 w-fit mx-auto">
        {tabs &&
          tabs.map((tab, index) => (
            <div
              key={index}
              className={`min-w-[140px] uppercase border-b py-4 text-center text-sm font-medium !leading-[20.3px] cursor-pointer 
            ${
              tab === activeTab
                ? "text-primary-200 border-primary-200"
                : "text-white border-grey-600"
            }`}
              onClick={() => handleTabClick(tab)}
            >
              {tab}
            </div>
          ))}
      </div>
    </div>
  );
}

export default Tab;

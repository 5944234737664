import React from "react";
import Icons from "../../../assets/icons";
import { Link } from "react-router-dom";

function ItemCard({ title, image, link }) {
  return (
    <div className="flex flex-col  w-[273px] sm:w-[286px] md:pb-[144px]">
      <img
        src={image}
        alt=""
        className="h-[260px]  object-cover"
      />
      <div className="bg-grey-10 p-4 -mt-1">
        <h5 className="text-grey-800 text-xl font-medium mb-4 uppercase h-[52px] line-clamp-2 text-ellipsis">
          {title}
        </h5>
        <Link
          to={`/news/${link}`}
          className="block bg-white p-2 border-2 border-solid border-grey-10 rounded-lg flex items-center gap-2 w-[218px] mx-auto"
        >
          <span className="text-primary text-sm uppercase font-semibold tracking-[1.68px]">
            Continue reading
          </span>
          {Icons.ic_new_tab}
        </Link>
      </div>
    </div>
  );
}

export default ItemCard;

import Icons from "../../assets/icons";
import image1 from "../../assets/images/about/accordion1.png";
import image2 from "../../assets/images/about/accordion2.png";
import image3 from "../../assets/images/about/accordion3.png";
import image4 from "../../assets/images/about/accordion4.png";
import useAccordion from "../../hooks/useAccordion";

const accordionData = [
  {
    question: "Network Code Support",
    answer: {
      image: image1,
      text: "GACN facilitates equitable access to the gas transportation network, ensuring efficiency and transparency in operations.",
    },
  },
  {
    question: "Price Aggregation and Escrow Management",
    answer: {
      image: image2,
      text: "We manage escrow accounts for transparent financial transactions, overseeing the aggregate gas pricing and payments",
    },
  },
  {
    question: "Commercial Gas Trading Platform Support",
    answer: {
      image: image3,
      text: "Supporting the development of a commercial gas trading platform, GACN boosts transactional efficiency and market transparency.",
    },
  },
  {
    question: "Gas Flare Commercialization Program Support",
    answer: {
      image: image4,
      text: "GACN is committed to the Gas Flare Commercialization Program, aiming to reduce environmental impacts and promote sustainable energy practices.",
    },
  },
];

export default function WhatWeDoAccordion() {
  const { openIndex, toggleAccordion } = useAccordion(true);

  return (
    <div className="max-w-[1008px] mx-auto">
      {accordionData.map((item, index) => (
        <div
          className="border-b border-grey-300 py-8 flex items-start gap-8"
          key={index}
        >
          <img
            src={item.answer.image}
            alt=""
            className={openIndex === index ? "hidden md:block" : "hidden"}
          />

          <div className="w-full">
            <h2 className="w-full text-lg md:text-xl text-left font-semibold flex justify-between">
              {item.question}
              <button
                className="min-h-[24px] min-w-[24px] block md:hidden"
                onClick={() => toggleAccordion(index)}
                aria-expanded={openIndex === index}
                aria-controls={`accordion-text-${index}`}
              >
                <img
                  src={Icons.ic_accordion}
                  alt=""
                  className={`w-[19px] transform origin-center transition duration-200 ease-out rotate-180 ${
                    openIndex === index && "!rotate-0"
                  }`}
                />
              </button>
            </h2>
            <div
              id={`accordion-text-${index}`}
              role="region"
              aria-labelledby={`accordion-title-${index}`}
              className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                openIndex === index
                  ? "grid-rows-[1fr] opacity-100 pt-6"
                  : "grid-rows-[0fr] opacity-0"
              }`}
            >
              <div className="overflow-hidden">
                <img
                  src={item.answer.image}
                  alt=""
                  className="w-full block md:hidden  pb-8"
                />
                <p className="text-grey-600 text-base md:text-lg !leading-[32px]">
                  {item.answer.text}
                </p>
              </div>
            </div>
          </div>
          <button
            className="min-h-[24px] min-w-[24px] hidden md:block"
            onClick={() => toggleAccordion(index)}
            aria-expanded={openIndex === index}
            aria-controls={`accordion-text-${index}`}
          >
            <img
              src={Icons.ic_accordion}
              alt=""
              className={`w-[19px] transform origin-center transition duration-200 ease-out rotate-180 ${
                openIndex === index && "!rotate-0"
              }`}
            />
          </button>
        </div>
      ))}
    </div>
  );
}

import React, { useState } from "react";
import Icons from "../../assets/icons";
import logo from "../../assets/images/logo/logo3.svg";
import { Link } from "react-router-dom";
import ContactForm from "./contactForm";
import FormSuccess from "./formSuccess";

function ContactModal({ toggleContactModal, isContactModalOpen }) {
  const [showForm, setShowForm] = useState(true);
  const toggleShowForm = (value) => setShowForm(value);

  return (
    <section
      className={`bg-grey-50 md:bg-white flex h-full min-h-screen w-full fixed top-0 left-0 z-[9999999999] transition-all ease-in-out duration-300 ${
        isContactModalOpen
          ? "visible min-h-screen translate-y-0 opacity-100"
          : "invisible min-h-0 translate-y-[-100%] opacity-0"
      }`}
    >
      <div className=" w-full md:w-1/2  pt-6 pb-[46px] md:pb-24 px-4 overflow-auto scrollbar">
        <header className="bg-grey-50 md:bg-white fixed top-0 left-0 w-full md:w-1/2 z-10">
          <div className="flex items-center justify-between md:ml-[77px] !py-4 md:!px-0 container">
            <Link to="/" aria-label="homepage">
              <img src={logo} width={150} alt="logo" />
            </Link>

            <div
              onClick={() => {
                toggleContactModal(false);
                toggleShowForm(true);
              }}
              className="md:hidden cursor-pointer bg-primary border border-primary-100 text-white h-10 w-10 rounded-full flex items-center justify-center"
            >
              {Icons.ic_close}
            </div>
          </div>
        </header>

        {showForm ? (
          <ContactForm toggleShowForm={toggleShowForm} />
        ) : (
          <FormSuccess
            toggleContactModal={toggleContactModal}
            toggleShowForm={toggleShowForm}
          />
        )}
      </div>
      <div className="bg-contact-bg bg-cover bg-bottom w-1/2 h-auto relative hidden md:flex flex-col justify-between pb-24 px-8">
        <div className="bg-primary-500 opacity-20 w-full h-full absolute top-0 left-0" />
        <div className="w-full mx-auto relative">
          <div
            onClick={() => {
              toggleContactModal(false);
              toggleShowForm(true);
            }}
            className="cursor-pointer bg-primary-500 border boder-solid border-primary-100 text-white h-12 w-12 rounded-full flex items-center justify-center absolute top-12 right-0"
          >
            {Icons.ic_close}
          </div>
        </div>

        <div className="max-w-[400px]  mx-auto self-end z-10">
          <h3 className="text-[28px] text-white font-semibold tracking-[-0.56px] pb-[10px] relative contact-stroke">
            CONNECTING THE ENERGY OF TOMORROW
          </h3>
          <p className="text-lg text-white ">
            At GACN, we're bridging the gap between today's needs and tomorrow's
            energy solutions. Reach out to us and be a part of this journey."
          </p>
        </div>
      </div>
    </section>
  );
}

export default ContactModal;

import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import FAQsAccordion from "../components/faq/accordion";

const FAQPage = () => {
  return (
    <Layout>
      <InnerBanner
        title="FREQUENTLY ASKED QUESTIONS"
        description="Your Questions Answered - Insights into GACN's Services and Operations"
      />
      <FAQsAccordion />
    </Layout>
  );
};

export default FAQPage;

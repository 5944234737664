import React from "react";
import overlay from "../../../assets/images/about/about_overlay.svg";
import Button from "../../../common/button";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <section id="about" className="bg-about-pattern bg-grey-900">
      <div className="bg-vector-lines bg-center bg-contain bg-no-repeat py-20">
        <div className="container ">
          <div className="flex flex-col bg-about-image bg-cover bg-center pt-12 pb-20 md:py-16 px-6 md:px-12 lg:px-20 relative">
            <div className="bg-black opacity-50 absolute top-0 left-0 w-full h-full" />
            <div
              src={overlay}
              className="bg-about-overlay backdrop-blur-[6px] w-full md:w-1/2 h-[48%] sm:h-[46%] md:h-full absolute top-0 left-0"
              alt=""
            />
            <h3 className="z-10 mb-10 md:mb-16 py-3 text-white text-xl md:text-[28px] font-bold tracking-[-0.56px] border-y border-solid border-white">
              <span className="text-primary-200">/</span> OUR STORY
            </h3>
            <div className="flex flex-col md:flex-row z-10">
              <div className="text-base lg:text-lg text-white md:max-w-[275px] lg:max-w-[325px] xl:max-w-[384px] pb-12 md:pb-10">
                <p className="pb-4">
                  GACN was formed in line with the statutory requirement of the
                  Nigerian Domestic Gas Supply & Pricing Regulations of 2008.
                </p>
                <p>
                  GACN is therefore the vehicle for implementation of the
                  Nigerian Gas Master Plan (NGMP) commercial framework.
                </p>
                <Link to="/about" className="mt-16 hidden md:block">
                  <Button>Learn more</Button>
                </Link>
              </div>
              <div className="md:self-end md:ml-auto md:-mt-4 pt-10">
                <h4 className="mb-8 md:mb-12 pb-4 text-white text-right md:text-left text-lg md:text-2xl font-semibold !leading-[28.8px] tracking-[-2%] border-b border-solid border-white">
                  VISION
                </h4>
                <p className="text-base lg:text-lg text-white md:max-w-[275px] lg:max-w-[325px]">
                  Driving natural gas commercialization and utilization in
                  Nigeria to create sustainable value for our
                  stakeholders and the Nation
                </p>
                <Link to="/about" className="mt-16 flex md:hidden">
                  <Button className="mx-auto">Learn more</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

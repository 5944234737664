import { useContext } from "react";
import { NewsModal } from "../context/newsContext";

const useNewsData = () => {
  const {
    state,
    dispatch,
    fetchNews,
    handleNewsTag,
    fetchRecentNews,
    handleNewsSearch,
    fetchNewsItem,
  } = useContext(NewsModal);

  const {
    news,
    recentNews,
    newsItem,
    searchNewsText,
    newsTag,
    error,
    loadingNews,
    loadingRecentNews,
  } = state;

  return {
    news,
    recentNews,
    newsItem,
    searchNewsText,
    newsTag,
    error,
    loadingNews,
    loadingRecentNews,
    dispatch,
    fetchNews,
    handleNewsTag,
    fetchRecentNews,
    handleNewsSearch,
    fetchNewsItem,
  };
};

export default useNewsData;

import React from "react";

import Layout from "../components/layout";
import InnerBanner from "../common/innerBanner";
import Gallery from "../components/gallery";

const GalleryPage = () => {
  return (
    <Layout>
      <InnerBanner
        title="PHOTO GALLERY"
        description="Capturing Moments from Our Journey in  the Gas Industry"
      />

      <Gallery />
    </Layout>
  );
};

export default GalleryPage;

import Icons from "../../assets/icons";
import useAccordion from "../../hooks/useAccordion";

export default function GeneralAccordion({ accordionData, type }) {
  const { openIndex, toggleAccordion } = useAccordion(false);

  return (
    <div
      className={`${
        type === "none" ? "bg-transparent" : "bg-grey-50 border border-grey-200"
      } py-12 px-4 md:px-12 space-y-8 max-w-[912px] mx-auto`}
    >
      {accordionData.map((item, index) => (
        <div
          className={`${
            type !== "none" && "last:border-none"
          } border-b border-grey-300 py-8 flex items-start gap-8`}
          key={index}
        >
          <div className="w-full flex justify-between items-start gap-6">
            <div className="flex items-start gap-6">
              {!type && <img src={Icons.ic_points} alt="" />}
              {type === "partnership" && (
                <img src={Icons.ic_points_thumb} alt="" />
              )}

              <div>
                <h2 className="w-full text-lg md:text-xl text-left font-semibold flex justify-between">
                  {item.question}
                </h2>

                <div
                  id={`accordion-text-${index}`}
                  role="region"
                  aria-labelledby={`accordion-title-${index}`}
                  className={`grid text-sm text-slate-600 overflow-hidden transition-all duration-300 ease-in-out ${
                    openIndex === index
                      ? "grid-rows-[1fr] opacity-100 pt-6"
                      : "grid-rows-[0fr] opacity-0"
                  }`}
                >
                  <div className="overflow-hidden">
                    {type === "faq" ? (
                      <div dangerouslySetInnerHTML={{ __html: item.answer }} />
                    ) : (
                      <p className="text-grey-600 text-base md:text-lg !leading-[32px]">
                        {item.answer}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <button
              className="min-h-[24px] min-w-[24px]"
              onClick={() => toggleAccordion(index)}
              aria-expanded={openIndex === index}
              aria-controls={`accordion-text-${index}`}
            >
              <img
                src={Icons.ic_accordion}
                alt=""
                className={`w-[19px] transform origin-center transition duration-200 ease-out rotate-180 ${
                  openIndex === index && "!rotate-0"
                }`}
              />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

const TextArea = (props) => {
  const { className, title, errors, ...rest } = props;

  return (
    <div className="relative">
      {title && (
        <label className="text-grey-900 text-sm font-medium  mb-1 block">
          {title}
        </label>
      )}

      <textarea
        className="bg-white p-4 w-full h-[96px] resize-none appearance-none border focus:outline-none focus:border-2 focus:border-primary border-grey-300 text-grey-900 rounded-md transition duration-150 ease-in-out"
        {...rest}
      ></textarea>

      {errors && <p className="text-xs text-error">{errors}</p>}
    </div>
  );
};

export default TextArea;

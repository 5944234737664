import { useEffect, useState } from "react";

const usePagination = (items, numberPerPage, searchText, activeTab) => {
  const itemsPerPage = numberPerPage ?? 6;
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    setItemOffset(0);
  }, [searchText, activeTab]);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = items.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(items.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage;

    setItemOffset(newOffset);
    window.scrollTo(0, 0);
  };
  // console.log(itemOffset, endOffset, items, currentItems);
  return { currentItems, pageCount, handlePageClick };
};

export default usePagination;
